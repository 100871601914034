import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingSpxView from 'x/modules/xshipping/BaseStoreSettingXShippingSpxView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import { IQuestionsAndAnswers } from 'x/index'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'

const IMG_SPX: ImageSourcePropType = require('../../images/courier/spx.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingXShippingSpxView extends BaseStoreSettingXShippingSpxView {
  static displayName = 'BaseUIStoreSettingXShippingSpxView'

  // abstract goBack: () => void
  // abstract renderWebView: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.shopee_express')}
    />
  )

  _renderUseFlashXShipjung = () => {
    const {
      useSpxxShipjung,
      useAutoXShippingIsSpxxShipjungReadyToShip,
      isSpxxShipjungLoading,
      loadingSpxxShipjungHeight,
      loadingSpxxShipjungWidth,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingSpxxShipjungHeight: width, loadingSpxxShipjungWidth: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useSpxxShipjung}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useSpxxShipjung'
          helper={{
            title: 'Flash ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useSpxxShipjung ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsSpxxShipjungReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsSpxxShipjungReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isSpxxShipjungLoading ? VIEW_LOADING(loadingSpxxShipjungWidth, loadingSpxxShipjungHeight) : null}
      </View>
    )
  }

  _renderFlashXShipjungCOD = () => {
    const {
      useSpxxShipjungCOD,
      useAutoXShippingIsSpxxShipjungCODReadyToShip,
      loadingSpxxShipjungCODHeight,
      loadingSpxxShipjungCODWidth,
      isSpxxShipjungCODLoading,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingSpxxShipjungCODWidth: width, loadingSpxxShipjungCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useSpxxShipjungCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useSpxxShipjungCOD'
          helper={{
            title: 'Flash ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useSpxxShipjungCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsSpxxShipjungCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsSpxxShipjungCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isSpxxShipjungCODLoading ? VIEW_LOADING(loadingSpxxShipjungCODWidth, loadingSpxxShipjungCODHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _getFAQAgreementForFlashxShipjung = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_FLASH_X_SHIPJUNG.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    FAQ.XSHIPPING_AGREEMENT_SHIPJUNG_COMMON.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderSpxxShipjung = () => {
    const { isRenderWebView } = this.state
    if (isRenderWebView) {
      return null
    }
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.500' }}
        borderRadius='md'
        style={{
          // backgroundColor: COLORS.WHITE,
          marginTop: 10,
          marginRight: 6,
          marginLeft: 6,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 15,
        }}>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <Image
            source={IMG_SPX}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 75,
              height: 30,
              backgroundColor: 'transparent',
              zIndex: 70,
              marginLeft: 0,
              marginRight: 25,
            }}
          />
          <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingRight: 20 }}>X</XText>
          <Image
            source={IMG_SHIPJUNG}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 90,
              height: 58,
              backgroundColor: 'transparent',
              zIndex: 70,
            }}
          />
        </Box>
        <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
          <XText variant='active'>ขอเลขพัสดุจาก Shopee Express ผ่าน Shipjung</XText>
        </Box>

        <XHelpModal
          key='XHelpModalSpxXShipjung'
          headerTitle='ข้อตกลงและวิธีการใช้งาน'
          FAQ={FAQ.XSHIPPING_SPX_AGREEMENT}
          initiateOpenIndex={0}
          renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
        />

        <Box style={{ height: 10 }} />
        <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.shopee_express')}`}</XText>
        </Box>
        {this._renderUseFlashXShipjung()}
        <Box style={{ height: 10 }} />
        <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.shopee_express_cod')}`}</XText>
        </Box>
        {this._renderFlashXShipjungCOD()}
      </Box>
    )
  }

  _renderUseSpxDirect = () => {
    const { useSpx, useAutoXShippingIsSpxReadyToShip, isSpxLoading, loadingSpxHeight, loadingSpxWidth } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingSpxWidth: width, loadingSpxHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useSpx}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useSpx'
          helper={{
            title: 'Flash ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useSpx ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsSpxReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsSpxReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isSpxLoading ? VIEW_LOADING(loadingSpxWidth, loadingSpxHeight) : null}
      </View>
    )
  }

  _renderSpxCODDirect = () => {
    const {
      useSpxCOD,
      useAutoCreateCODPaymentWhenDelivered,
      useAutoXShippingIsSpxCODReadyToShip,
      loadingSpxCODHeight,
      loadingSpxCODWidth,
      isSpxCODLoading,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingSpxCODWidth: width, loadingSpxCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useSpxCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useSpxCOD'
          helper={{
            title: 'SPX ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useSpxCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsSpxCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsSpxCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useSpxCOD ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={useAutoCreateCODPaymentWhenDelivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoCreateCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isSpxCODLoading ? VIEW_LOADING(loadingSpxCODWidth, loadingSpxCODHeight) : null}
      </View>
    )
  }

  _renderSpxDirect = () => {
    const { isRenderWebView, spxConnectedData, userId } = this.state
    if (isRenderWebView) {
      return null
    }

    const btnText = !_.isNil(spxConnectedData) ? 'รายละเอียดการเชื่อมต่อ' : 'เชื่อมต่อ SPX'
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.500' }}
        borderRadius='md'
        style={{
          // backgroundColor: COLORS.WHITE,
          marginTop: 10,
          marginRight: 6,
          marginLeft: 6,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 15,
        }}>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
          <Image
            source={IMG_SPX}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 75,
              height: 30,
              backgroundColor: 'transparent',
              zIndex: 70,
              // marginLeft: -30,
            }}
          />
        </Box>
        <HStack style={{ justifyContent: 'center', alignItems: 'center', marginTop: 4 }}>
          <XText variant='active'>ขอเลขพัสดุจาก Shopee Express</XText>
          <XText variant='active' bold>
            {' โดยตรง'}
          </XText>
        </HStack>
        <XHelpModal
          key='XHelpModalSpx'
          headerTitle='ข้อตกลงและวิธีการใช้งาน'
          FAQ={FAQ.XSHIPPING_SPX_AGREEMENT}
          initiateOpenIndex={0}
          renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
        />
        <Box h='2' />

        <Box justifyContent='center' alignItems='center'>
          <XButton onPress={() => this._navToStoreSettingSpx()} w='40'>
            <XText color='white' bold alignSelf='center'>
              {btnText}
            </XText>
          </XButton>
        </Box>

        {_.isNil(userId) ? null : (
          <XText variant='inactive' textAlign='center' mt='2'>
            {`(เชื่อมต่อกับ User Id : ${userId})`}
          </XText>
        )}

        {!_.isNil(spxConnectedData) ? (
          <VStack>
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.shopee_express')}`}</XText>
            </Box>
            {this._renderUseSpxDirect()}
            <Box style={{ height: 10 }} />
            <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
              <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
              <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.shopee_express_cod')}`}</XText>
            </Box>
            {this._renderSpxCODDirect()}
          </VStack>
        ) : null}
      </Box>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>
            {this._renderSpxDirect()}
            {this._renderSpxxShipjung()}
          </Box>
        </XContent>
      </XContainer>
    )
  }
}
