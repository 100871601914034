import p from 'x/config/platform-specific'
// use interface => IQuestionsAndAnswers
const FAQ = {
  //
  FORCE_CREATE_SHIPPING: [
    {
      q: '"บันทึกจัดส่งแม้จำนวนสินค้าในคลังไม่พอ" คืออะไร?',
      a:
        'โดยปกติหากจำนวนสินค้าในออเดอร์มีไม่พอในคลัง ระบบจะไม่ให้บันทึกจัดส่งสำหรับออเดอร์นั้นๆ ในกรณีนี้ หากคุณต้องการบันทึกจัดส่ง คุณสามารถทำได้ 2 วิธี ได้แก่' +
        '\n\n1) เพิ่มจำนวนสินค้าในคลังแล้วบันทึกจัดส่งใหม่ หรือ' +
        '\n\n2) เปิดสวิทช์ "บันทึกจัดส่งแม้จำนวนสินค้าในคลังไม่พอ" เพื่ออนุญาตให้ระบบสามารถบันทึกจัดส่งได้ และปรับจำนวนสินค้าในคลังให้เป็น 0',
    },
    {
      q: 'ตัวอย่างการใช้งาน',
      a:
        'หากสินค้า A มีจำนวนในคลังเป็น 2 และคุณต้องการบันทึกจัดส่งสำหรับออเดอร์ที่สั่ง สินค้า A จำนวน 3 ชิ้น หากคุณเปิดสวิทช์ "บันทึกจัดส่งแม้จำนวนสินค้าในคลังไม่พอ" ระบบจะบันทึกการจัดส่งให้ และปรับจำนวนสินค้า A ในคลังให้เป็น 0',
    },
  ],
  //
  AUTO_CANCAL_ORDER: [
    {
      q: 'ในแต่ละออเดอร์จะสามารถเลือกตั้งค่าได้ 1 ใน 3 อย่างนี้',
      a:
        '1) ไม่ใช้งาน: \nออเดอร์นี้จะไม่มีกำหนดชำระและระบบจะไม่ยกเลิกออเดอร์ให้' +
        '\n\n2) ระบุ: \nในแต่ละออเดอร์ คุณสามารถระบุวันและเวลากำหนดชำระได้ตามต้องการ' +
        '\n\n3) ใช้ตามตั้งค่าร้าน: \nระบบจะตั้งกำหนดชำระให้อัตโนมัติเมื่อมีการสร้างออเดอร์ ตามจำนวนชั่วโมงที่ระบุที่ตั้งค่าร้าน นับจากวันเวลาที่สร้างออเดอร์',
    },
    {
      q: 'ยกเลิกออเดอร์อัตโนมัติทำงานอย่างไร',
      a:
        'ระบบจะทำการยกเลิกออเดอร์อัตโนมัติ เมื่อไม่มีการแจ้งชำระภายในระยะเวลาที่กำหนด สำหรับออเดอร์ขายของคุณที่ ' +
        'ยังไม่ได้จัดส่ง ไม่มีการแจ้งชำระภายในระยะเวลาที่กำหนด และสวิตซ์ "จัดส่งก่อนชำระ" ปิดการใช้งานอยู่',
    },
  ],
  HIDE_PAYMENT_ACCOUNT_FROM_ORDER_LINK: [
    {
      q: 'วิธีตั้งค่าซ่อน-แสดงบัญชีรับชำระ',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/order/hide_show_accounting.png',
          t: 'วิธีซ่อน/แสดง\nบัญชีรับชำระให้ลูกค้า เลือกชำระผ่านลิงก์ออเดอร์',
          u: 'https://www.youtube.com/watch?v=LhKNY3jwrx8',
        },
      ],
      // a: 'เมื่อเปิดสวิสต์ บัญชีนี้จะไม่แสดงในลิงก์ออเดอร์เมื่อลูกค้าเลือกบัญชีรับชำระ',
    },
    {
      q: 'ซ่อนบัญชีนี้จากลิงก์ออเดอร์',
      a: 'เมื่อเปิดสวิสต์ บัญชีนี้จะไม่แสดงในลิงก์ออเดอร์เมื่อลูกค้าเลือกบัญชีรับชำระ',
    },
    {
      q: 'ลิงก์ออเดอร์ คือ อะไร',
      a:
        'แต่ละออเดอร์ จะมีลิงก์เฉพาะของแต่ละออเดอร์เอง โดยในลิงก์จะแสดงข้อมูลออเดอร์เช่น ที่อยู่จัดส่ง และสถานะการชำระ ซึ่งลูกค้าสามารถแจ้งชำระและแนบสลิปผ่านลิงก์นี้ได้' +
        '\n\nโดยปกติเมื่อสร้างออเดอร์เสร็จ ลิงก์นี้จะถูกคัดลอกโดยอัตโนมัติ หรือคุณสามารถกดปุ่มคัดลอกเองได้ในหน้ารายละเอียดออเดอร์เมื่อต้องการ',
    },
  ],
  DATE_DELIVERY_FAQ: [
    {
      q: 'คุณสามารถระบุ "วันกำหนดส่ง" ในแต่ละออเดอร์ได้ จากนั้น คุณสามารถ...',
      a:
        '1) ดูรายการออเดอร์ตามช่วง "วันกำหนดส่ง" ได้' +
        '\n2) พิมพ์ใบปะหน้าพัสดุตามช่วง "วันกำหนดส่ง" ได้' +
        '\n3) โหลดรายงาน "การขายสินค้ารายวัน" > เลือกตาม "วันกำหนดส่ง" เพื่อดูจำนวนสินค้าที่มีกำหนดส่งในแต่ละวันได้' +
        '\n4) โหลดรายงาน "ยอดขายอย่างละเอียด" เพื่อดูข้อมูล "วันกำหนดส่งของแต่ละออเดอร์ได้"' +
        '\n5) ประยุกต์การใช้งานอื่นๆ ได้ตามต้องการ',
    },
  ],
  //
  XSHIPPING: [
    {
      q: p.op.t('xshipping.faq.what_is_xshipping'),
      a: p.op.t('xshipping.faq.what_is_xshipping_answer'),
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/set_xshipping.png',
          t: 'วิธีตั้งค่าเปิดใช้งาน XShipping ออกเลขพัสดุ',
          u: 'https://www.youtube.com/watch?v=WjbPVMf-51o',
        },
      ],
    },
    {
      q: 'ติดตามสถานะพัสดุได้ไหม',
      a:
        'ได้! ทั้งคุณ ลูกค้า และตัวแทนคุณ(ถ้ามี) สามารถติดตามสถานะการจัดส่งของแต่ละออเดอร์ได้ง่ายๆผ่านแอป XSelly (โดยกดดูได้ในแต่ละออเดอร์) หรือผ่าน เว็บลิงก์ออเดอร์ที่ส่งให้ลูกค้า (ลูกค้าสามารถกดดูผ่านลิงก์ที่ส่งให้ในแชทได้เลย ไม่ต้องลงแอป)',
    },
    {
      q: 'มีค่าใช้จ่ายเพิ่มไหม',
      a: 'ฟรี! ฟีเจอร์ XShipping ไม่มีค่าใช้จ่ายเพิ่ม คุณชำระเพียงค่าส่งและค่าธรรมเนียมเก็บเงินปลายทาง',
    },
    {
      q: 'เรทค่าส่ง การเก็บเงินปลายทาง และการนำส่งพัสดุเป็นอย่างไร',
      a: 'คุณสามารถดูข้อมูลเหล่านี้ได้ในแต่ขนส่ง โดยเปิดที่หน้าขนส่งแล้วกดปุ่ม “ดูข้อตกลง และวิธีการใช้งาน”',
    },
    {
      q: 'ยอดเก็บเงินปลายทาง [COD] จะตกหล่นไหม',
      a:
        'ไม่ตกหล่น 100% เพราะแต่ละเลขพัสดุที่ขอมาได้จะถูกระบุและล็อกเป็น COD เลย ตัดปัญหาความผิดพลาดปัญหาต่าง เช่นพนักงานจากขนส่งที่อาจตกหล่นในการคีย์ข้อมูล COD ด้วยตนเอง',
    },
    {
      q: 'แก้ไขข้อมูลออเดอร์หลังได้เลขติดตามพัสดุได้ไหม',
      a:
        'แก้ได้! โดยเมื่อแก้ออเดอร์เสร็จแล้ว คุณต้องยกเลิกเลขพัสดุเดิม (กดปุ่มกากบาทสีแดงหน้าเลขพัสดุ) หากแก้ไข ชื่อที่อยู่ผู้รับผู้ส่ง หรือ หากแก้ไขยอดเก็บเงินปลายทางสำหรับออเดอร์ที่ส่งแบบ [COD]',
    },
  ],
  VERIFY_SLIP_CREDIT_SUMMARY: [
    {
      q: 'เครดิตมีไว้ทำอะไร',
      a: '1 เครดิตใช้ตรวจได้ 1 สลิป',
    },
    {
      q: 'จะเติมเครดิตได้อย่างไร',
      a: 'กรุณาติดต่อทีมงาน',
    },
    {
      q: 'เครดิตมีหมดอายุหรือไม่',
      a: 'เครดิตมีอายุ 1 ปี',
    },
    {
      q: 'สามารถเติมเครดิตก่อนหมดได้หรือไม่',
      a: 'ได้! คุณสามารถเติมกี่รอบก็ได้ตามต้องการ',
    },
    {
      q: 'เครดิตคงเหลือคืออะไร',
      a: 'คือยอดรวมเครดิตทั้งหมดที่ยังไม่ถูกใช้งานและยังไม่หมดอายุ',
    },
    {
      q: 'ถ้าตรวจสลิปเดิมซ้ำ เสียเครดิตเพิ่มหรือไม่',
      a: 'ไม่เสียเพิ่ม!',
    },
  ],
  XSHIPPING_KERRY_AGREEMENT: [
    {
      q: 'เรทค่าส่งเท่าไหร่',
      a: 'ค่าส่งและโปรโมชั่นตามที่ตกลงไว้กับ Kerry Shop เลย',
    },
    {
      q: 'ชำระค่าส่งอย่างไร',
      a: 'ชำระให้กับ Kerry Shop ได้โดยตรงเลย',
    },
    {
      q: 'รับชำระยอดเก็บเงินปลายทางอย่างไร',
      a: 'รับชำระจาก Kerry Shop ได้โดยตรงเลย',
    },
    {
      q: 'นำส่งพัสดุอย่างไร',
      a: 'ไปส่งเองที่ Kerry Shop โดยหากต้องการให้พนักงาน Kerry เข้ารับให้จะต้องตกลงกับทาง Shop เอง',
    },
    {
      q: 'เปิดการใช้งานแล้วยังไงต่อ',
      a:
        'เมื่อเปิดการใช้งานสำหรับแต่ละ”รูปแบบจัดส่ง”แล้ว ที่แต่ละออเดอร์ให้เลือกรูปแบบขนส่งเป็น “Kerry Express” หรือ “Kerry Express [COD]” ตามที่ได้เปิดการใช้งานไว้ จากนั้น ที่แต่ละออเดอร์ คุณสามารถขอเลขพัสดุได้ด้วยขั้นตอนดังนี้' +
        '\n1) ในหน้ารายการ หรือ รายละเอียดออเดอร์ กดปุ่ม “ขอเลขพัสดุทันที”' +
        '\nหรือ' +
        '\n2) เมื่อออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ระบบจะขอเลขพัสดุให้อัตโนมัติ หากคุณเปิดการใช้งาน “ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” ไว้' +
        '\nสำหรับออเดอร์ที่สร้างไว้ก่อนเปิดการใช้งานฟีเจอร์นี้ คุณสามารถทำตามข้อ 1) เพื่อขอเลขพัสดุแต่ละออเดอร์ได้',
    },
    {
      q: 'ที่อยู่ผู้ส่ง สำคัญไฉน',
      a:
        'เมื่อมีการขอเลขพัสดุ XSelly จะส่งข้อมูลชื่อ ที่อยู่ และเบอร์โทรของทั้ง “ผู้ส่ง และ ผู้รับ” ตามที่ระบุไว้ในแต่ละออเดอร์ไปให้ทาง Kerry ดังนั้น “จึงจำเป็นที่จะต้องกรอกข้อมูล ผู้ส่งและผู้รับ ให้ครบถ้วน ถูกต้อง”',
    },
    {
      q: 'เชื่อมบัญชีที่ลงทะเบียนไว้กับ Kerry ได้อย่างไร',
      a:
        'หากมีการลงทะเบียนเป็นสมาชิกไว้กับ Kerry อยู่แล้ว เช่นสำหรับการสะสมแต้มผ่านแอป หรือ ดีลกับเซลล์ Kerry ไว้ สามารถเชื่อมบัญชีกันได้โดย ในแต่ละออเดอร์ ให้ระบุเบอร์โทรศัพท์ของผู้ส่ง ให้ตรงกับเบอร์ที่ลงทะเบียนไว้กับทาง Kerry',
    },
    {
      q: 'Kerry Sameday ส่งได้ไหม',
      a:
        'ส่งได้! เมื่อส่งพัสดุกับทางสาขาโดยตรง สามารถใช้เลขพัสดุที่ออกจาก XSelly ได้เลย เพียงแจ้งพนักงานว่าส่ง Sameday\n' +
        '\nส่งไม่ได้! เมื่อส่งพัสดุผ่านทางรถที่เข้ารับสำหรับ Kerry Sameday โดยเฉพาะ',
    },
    {
      q: 'กรณีไหนที่ใช้งานฟีเจอร์ XShipping ออกเลขพัสดุจาก Kerry ไม่ได้',
      a:
        'หากคุณชำระค่าจัดส่งแบบวางบิลรายเดือน (นั้นคือ ส่งก่อนทั้งเดือน แล้วทาง Kerry รวมยอดค่าส่งมาวางบิลให้ชำระเดือนละครั้ง) จะไม่สามารถใช้งานฟีเจอร์นี้ได้',
    },
    {
      q: 'เลขติดตามพัสดุมีหมดอายุไหม',
      a:
        'คุณต้องนำส่งให้ Kerry Shop ยิงเลขพัสดุนี้ภายใน 30 วัน นับจากวันที่ขอ หากเกินกว่านั้น คุณต้องยกเลิกเลขพัสดุเดิม (โดยกดปุ่มกากบาทสีแดงหน้าเลขพัสดุ) แล้วกดปุ่ม “ขอเลขพัสดุทันที” (และพิมพ์ใบปะหน้าพัสดุใหม่ หากพิมพ์ไปแล้ว) เพื่อใช้เลขใหม่แทนเลขเก่า โดยคุณยังใช้ออเดอร์เดิมได้ ยกเลิกแค่เลขติดตามพัสดุจาก Kerry เท่านั้น',
    },
  ],
  XSHIPPING_NOT_COD: [
    {
      q: 'การขอเลขพัสดุ แบบ"ไม่เก็บ"เงินปลายทาง คืออะไร',
      a:
        'เมื่อเปิดการใช้งาน คุณจะสามารถขอเลขติดตามพัสดุจากขนส่งได้ สำหรับออเดอร์ที่ไม่ได้เก็บเงินปลายทาง โดยสามารถกดที่ปุ่ม “ขอเลขพัสดุทันที” หรือให้ระบบขอเลขพัสดุอัตโนมัติให้ได้เมื่อออเดอร์พร้อมจัดส่ง',
    },
  ],
  XSHIPPING_COD: [
    {
      q: 'การขอเลขพัสดุ แบบ"เก็บ"เงินปลายทาง คืออะไร',
      a:
        'เมื่อเปิดการใช้งาน คุณจะสามารถขอเลขติดตามพัสดุจากขนส่งได้ สำหรับออเดอร์ที่เก็บเงินปลายทาง โดยสามารถกดที่ปุ่ม “ขอเลขพัสดุทันที” หรือให้ระบบขอเลขพัสดุอัตโนมัติให้ได้เมื่อออเดอร์พร้อมจัดส่ง',
    },
  ],
  XSHIPPING_READY_TO_SHIP: [
    {
      q: '“ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” คืออะไร',
      a:
        'หากเปิดการใช้งานฟีเจอร์นี้ ระบบจะขอเลขติดตามพัสดุจากขนส่งให้แต่ละออเดอร์โดยอัตโนมัติหากออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ซึ่งคือ' +
        '1) บันทึกรับชำระมาครบถ้วน' +
        'หรือ' +
        '2) เปิดสวิทช์ “จัดส่งก่อนชำระ” ไว้ (เช่นเมื่อสร้างออเดอร์ที่มีรูปแบบขนส่งเป็นแบบเก็บเงินปลายทาง [COD] ซึ่งระบบจะขอเลขพัสดุให้ทันทีที่สร้างออเดอร์เสร็จ)',
    },
  ],
  XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED: [
    {
      q: '“บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว” คืออะไร',
      a:
        'สำหรับออเดอร์ที่มีการเก็บเงินปลายทาง [COD] คุณสามารถเปิดการใช้งานฟีเจอร์นี้ได้เพื่อให้ระบบบันทึกรับชำระให้อัตโนมัติเมื่อออเดอร์นั้นๆได้ส่งถึงผู้รับแล้ว',
    },
    {
      q: 'ไม่ควรเปิดการใช้งานฟีเจอร์นี้เมื่อไหร่',
      a:
        '*ปิด*การใช้งานฟีเจอร์นี้ เมื่อคุณต้องการตรวจสอบความถูกต้องของการรับชำระเงินตามจริง เพื่อที่คุณจะสามารถกระทบยอดเองได้ในภายหลังเมื่อได้รับโอนเงินจากทางขนส่งแล้ว ซึ่งคุณอาจทำได้ด้วย 2 วิธีนี้' +
        '1) กดบันทึกรับชำระเองแต่ละออเดอร์ที่ได้รับเงินแล้ว' +
        'หรือ' +
        '2) ใช้ฟีเจอร์ “นำเข้าข้อมูล > กระทบยอด COD” เพื่อนำไฟล์ Excel ที่ทางขนส่งส่งให้เราในทุกๆครั้งที่ได้รับเงิน เป็นสรุปยอดเงินโอนพร้อมเลขติดตามพัสดุ มานำเข้าให้ระบบตรวจสอบให้ สามารถทำได้ทีละหลายพันออเดอร์ต่อครั้ง ทำผ่านแอปหรือเว็บก็ได้ โดยระบบจะบันทึกรับชำระให้แต่ละออเดอร์ที่บันทึกจัดส่งด้วยเลขติดตามพัสดุที่ตรงกัน ตามยอดเก็บเงินที่ระบุใน Excel',
    },
    {
      q: 'ควรเปิดการใช้งานฟีเจอร์นี้เมื่อไหร่',
      a: '*เปิด*การใช้งานฟีเจอร์นี้ เมื่อคุณ*ไม่ต้องการตรวจสอบความถูกต้อง*ของการโอนเงินที่เก็บปลายทางมาได้จากขนส่ง',
    },
  ],
  XSHIPPING_J_AND_T_AGREEMENT: [
    {
      q: 'เรทค่าส่งเท่าไหร่',
      a: 'ค่าส่ง ค่าธรรมเนียม COD  และโปรโมชั่นตามที่คุณตกลงไว้กับ J&T Shop เลย เช่นถ้ามีดีลพิเศษกับทาง Shop อยู่แล้ว ก็ใช้งานต่อได้เลย',
    },
    {
      q: 'ชำระค่าส่งอย่างไร',
      a: 'ชำระให้กับ J&T Shop ได้โดยตรงเลย',
    },
    {
      q: 'รับชำระยอดเก็บเงินปลายทางอย่างไร',
      a: 'รับชำระจาก J&T Shop ได้โดยตรงเลย',
    },
    {
      q: 'นำส่งพัสดุอย่างไร',
      a: 'ไปส่งเองที่ J&T Shop โดยหากต้องการให้พนักงาน J&T เข้ารับให้จะต้องตกลงกับทาง Shop เอง',
    },
    {
      q: 'เปิดใช้งานต้องทำอย่างไร',
      a:
        'กดที่ปุ่ม "ตั้งค่าการลงทะเบียน" เพื่อกรอกข้อมูลที่อยู่ผู้ส่งและรับรหัสการเชื่อมต่อ จากนั้น..\n\n' +
        '👉 หากคุณมีบัญชี VIP กับ J&T Shop อยู่แล้ว คุณสามารถคัดลอก ข้อความในกล่องที่มีรหัสการเชื่อมต่อ (Shop ID) ' +
        'และชื่อร้าน (Shop Name) ไปแจ้งกับสาขา J&T ที่คุณมีบัญชี VIP เพื่อทำการเชื่อมต่อ ' +
        'โดยคุณจะต้องรอทางสาขาเชื่อมต่อสำเร็จก่อนที่จะออกเลขพัสดุ J&T ผ่าน XSelly ได้\n\n' +
        '👉 หากคุณยังไม่ได้มีบัญชี VIP คุณสามารถนำพัสดุไปส่งที่สาขาที่แสดงได้เลยหลังจากที่ "ตั้งค่าการลงทะเบียน" จากแอป XSelly เรียบร้อยแล้ว ' +
        'โดยหากต้องการเปลื่ยนสาขา รบกวนสอบถาม"รหัสสาขา"ของสาขาที่ต้องการเชื่อม หรือหากเคยส่งพัสดุกับสาขานี้มาก่อนก็สามารถใช้"เลขพัสดุ"ได้ แล้วติดต่อมาที่ LINE: @XSelly ' +
        'เมื่อทางสาขาเชื่อมต่อให้แล้ว คุณสามารถกดปุ่ม "อัพเดทข้อมูลสาขา" เพื่อดูช้อมูลล่าสุดได้',
    },
    {
      q: 'ออกเลขพัสดุอย่างไรเมื่อเปิดใช้งานแล้ว',
      a:
        'ในออเดอร์ เลือกรูปแบบขนส่งเป็น “J&T Express” หรือ “J&T Express [COD]”' +
        '\nจากนั้น ที่แต่ละออเดอร์ คุณสามารถขอเลขพัสดุได้ด้วยขั้นตอนดังนี้' +
        '\n1) ในหน้ารายการ หรือ รายละเอียดออเดอร์ กดปุ่ม “ขอเลขพัสดุทันที”' +
        '\nหรือ' +
        '\n2) เมื่อออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ระบบจะขอเลขพัสดุให้อัตโนมัติ หากคุณเปิดการใช้งาน “ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” ไว้' +
        '\nสำหรับออเดอร์ที่สร้างไว้ก่อนเปิดการใช้งานฟีเจอร์นี้ คุณสามารถทำตามข้อ 1) เพื่อขอเลขพัสดุแต่ละออเดอร์ได้',
    },
    {
      q: 'ยกเลิกการเชื่อมต่ออย่างไร',
      a:
        'หากคุณไม่ต้องการเชื่อมต่อกับสาขาเดิม หรือต้องการเปลี่ยนสาขา คุณสามารถกดปุ่ม "ตั้งค่าการลงทะเบียน" > "ยกเลิกการเชื่อมต่อ" แล้วทำการเชื่อมต่อใหม่ถ้าต้องการ\n\n' +
        'โดยเลขพัสดุที่ออกมาแล้วก่อนหน้าการยกเลิกการเชื่อมต่อ จะยังคงส่งที่สาขาเดิมได้อยู่ หากต้องการส่งที่สาขาใหม่ต้องยกเลิกเลขพัสดุเดิม ' +
        'และขอเลขพัสดุใหม่หลังจากที่ได้รับรหัสการเชื่อมต่อ (Shop ID) ใหม่แล้ว',
    },
  ],
  XSHIPPING_FLASH_AGREEMENT: [
    {
      q: 'เรทค่าส่งเท่าไหร่',
      a: 'ค่าส่ง ค่าธรรมเนียม COD และโปรโมชั่นตามที่คุณตกลงไว้กับ Flash เลย เช่นถ้ามีดีลพิเศษกับทาง Flash อยู่แล้ว ก็ใช้งานต่อได้เลย',
    },
    {
      q: 'ชำระค่าส่งอย่างไร',
      a: 'ชำระให้กับ Flash ได้โดยตรงเลย',
    },
    {
      q: 'รับชำระยอดเก็บเงินปลายทางอย่างไร',
      a: 'รับชำระจาก Flash ได้โดยตรงเลย',
    },
    {
      q: 'นำส่งพัสดุและเรียกนัดรับอย่างไร',
      a:
        '*ในการนำส่ง* คุณสามารถไปส่งเองได้ที่สาขา Flash หรือ Flash home\n\n' +
        '*ในการให้เข้ารับพัสดุ* หากคุณไม่มีบัญชีธุรกิจกับ Flash คุณสามารถใช้แอป Flash ในการเรียกนัดรับได้เองเมื่อต้องการ โดยไปที่\n\n"ส่งพัสดุ (Booking) > เรียกพนักงานเข้ารับพัสดุ (Notify the Courier quickly)"' +
        '\n\nแต่หากคุณมีบัญชีธุรกิจกับ Flash และมีวิธีการเรียกเข้ารับอยู่แล้ว คุณสามารถเรียกเข้ารับด้วยวิธีเดิมได้เลย' +
        '\n\nหมายเหตุ: เบอร์ที่ใช้ในการล็อกอินเพื่อเปิดการเชื่อมต่อ Flash ผ่านแอป XSelly ต้องเป็นเบอร์เดียวกับที่ใช้ล็อกอินเข้าแอป Flash หรือระบบหลังบ้าน Flash',
    },
    {
      q: 'เปิดใช้งานต้องทำอย่างไร',
      a:
        'ง่ายๆ พร้อมใช้งานทันที ด้วยชั้นตอนดังนี้\n' +
        '1. โหลดแอป Flash จาก App Store / Play Store (ถ้ายังไม่มีแอป)\n' +
        '2. ลงทะเบียนในแอป Flash (ถ้ายังไม่มีบัญชี)\n' +
        '3. กดปุ่ม "ดำเนินการเชื่อมต่อ" ในหน้าการตั้งค่านี้\n' +
        '4. กรอกข้อมูลตามหน้าไปจนเสร็จสิ้น แล้วกดเปิดสวิตซ์ \n' +
        'ใช้งานการขอเลขพัสดุ ในแบบที่ต้องการได้ทันที',
      // 'หมายเหตุ: ลิงก์มีอายุ 30 นาทีหลังจากสร้าง หากเลยเวลา กรูณากดปุ่ม "ดำเนินการเชื่อมต่อ" ใหม่อีกครั้ง',
    },
    {
      q: 'จะรู้ได้อย่างไรว่าเชื่อมสำเร็จ',
      a:
        'ดูง่ายๆได้ 3 วิธี (เพียงวิธีใดวิธีนึงก็ถือว่าเชื่อมสำเร็จแล้ว\n' +
        '1. เห็นคำว่า "เชื่อมบัญชีสำเร็จ" พร้อม ติ๊กสีเขียว ในหน้าสุดท้ายของลิงก์ผูกบัญชี Flash หรือ\n' +
        '2. เห็นแจ้งเตือนคำว่า "The account has been linked. Please do not reconnect the account." พร้อม กากบาทสีแดง ในหน้าสุดท้ายของลิงก์ผูกบัญชี Flash หรือ\n' +
        '3. ล็อคอินที่ https://www.flashexpress.co.th/login > กดเมนู "ส่งพัสดุ" > กดเมนู "Link Account" ด้านซ้ายล่าง ' +
        'โดยจะต้องเห็นชื่อแพลตฟอร์ม "บริษัท เอฟฟิลี่ จำกัด" และมี ✅ อยู่ด้านหน้า\n',
    },
    {
      q: 'เชื่อมต่อซ้ำๆ ได้หรือไม่',
      a:
        'ได้! หากจำไม่ได้ว่าเชื่อมไปหรือยัง หรือเพื่อความมั่นใจ คุณสามารถกดปุ่ม "ดำเนินการเชื่อมต่อ" ใหม่แล้วเชื่อมต่ออีกครั้งได้ โดยจะทราบได้ว่าการเชื่อมต่อสำเร็จ เมื่อเห็นแจ้งเตือนคำว่า ' +
        '"The account has been linked. Please do not reconnect the account." พร้อม กากบาทสีแดง ในหน้าสุดท้ายของลิงก์ผูกบัญชี Flash',
    },
    {
      q: 'ออกเลขพัสดุอย่างไรเมื่อเปิดใช้งานแล้ว',
      a:
        'ในออเดอร์ เลือกรูปแบบขนส่งเป็น “Flash Express” หรือ “Flash Express [COD]”' +
        '\nจากนั้น ที่แต่ละออเดอร์ คุณสามารถขอเลขพัสดุได้ด้วยขั้นตอนดังนี้' +
        '\n1) ในหน้ารายการ หรือ รายละเอียดออเดอร์ กดปุ่ม “ขอเลขพัสดุทันที”' +
        '\nหรือ' +
        '\n2) เมื่อออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ระบบจะขอเลขพัสดุให้อัตโนมัติ หากคุณเปิดการใช้งาน “ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” ไว้' +
        '\nสำหรับออเดอร์ที่สร้างไว้ก่อนเปิดการใช้งานฟีเจอร์นี้ คุณสามารถทำตามข้อ 1) เพื่อขอเลขพัสดุแต่ละออเดอร์ได้',
    },
    {
      q: 'นำส่งพัสดุเองที่ Flash Home ได้ไหม',
      a:
        'ได้! คุณสามารถนำพัสดุไปส่งที่ Flash Home ได้เลยโดยไม่ต้องทำการนัดรับผ่านแอป Flash ก่อน\n\n' +
        'โดยหากทาง Flash Home สแกนเลขพัสดุ Flash ที่ออกผ่าน XSelly ไม่ได้ ' +
        'ให้แจ้งทาง Flash Home ว่าให้ใช้ฟังก์ชั่นเมนูชื่อ "รับพัสดุบาร์โค้ดเล็ก" ในการสแกนเลขพัสดุ Flash',
    },
    {
      q: 'ออกเลขพัสดุแล้วยังไม่ส่งได้ไหม',
      a:
        'ได้ คุณสามารถออกเลขพัสดุมาล่วงหน้าได้ 1 เดือน และสามารถพิมพ์ใบปะหน้าพัสดุออกมาก่อนได้หากต้องการ\n\n' +
        'เมื่อต้องการจัดส่ง สามารถใช้แอป Flash ในการเรียกนัดรับ หรือไปส่งเองที่ สาขา Flash หรือ Flash Home ได้เลย',
    },
    {
      q: 'ยกเลิกการเชื่อมต่ออย่างไร',
      a:
        'เพียงปิดสวิตท์การออกเลขพัสดุใน XSelly ก็เพียงพอแล้ว แต่ถ้าคุณต้องการแจ้งยกเลิกการเชื่อมให้ทาง Flash ทราบ สามารถทำได้ดังนี้\n' +
        '1. ล็อคอินที่ https://www.flashexpress.co.th/login\n' +
        '2. กดเมนู "ส่งพัสดุ"\n' +
        '3. กดเมนู "Link Account\n' +
        '4. กดเช็กออก ที่เครื่องหมาย ✅ หน้าชื่อแพลตฟอร์ม "บริษัท เอฟฟิลี่ จำกัด"',
    },
  ],
  XSHIPPING_AGREEMENT_SHIPJUNG_COMMON: [
    {
      q: p.op.t('xshipping.faq.shipping_rate'),
      a: p.op.t('xshipping.faq.shipping_rate_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_to_deliver'),
      a: p.op.t('xshipping.faq.how_to_deliver_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_do_enable'),
      a: p.op.t('xshipping.faq.how_do_enable_answer'),
    },
    {
      q: p.op.t('xshipping.faq.enabled_how_to_use'),
      a: p.op.t('xshipping.faq.enabled_how_to_use_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_to_pay_for_shipping'),
      a: p.op.t('xshipping.faq.how_to_pay_for_shipping_answer'),
    },
    {
      q: p.op.t('xshipping.faq.when_is_shipping_credit_cut_off'),
      a: p.op.t('xshipping.faq.when_is_shipping_credit_cut_off_answer'),
    },
    {
      q: p.op.t('xshipping.faq.cancel_shipping_how_to_refund'),
      a: p.op.t('xshipping.faq.cancel_shipping_how_to_refund_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_receive_payment_for_cash_on_delivery_cod'),
      a: p.op.t('xshipping.faq.how_receive_payment_for_cash_on_delivery_cod_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_to_link_bank_account_for_cod'),
      a: p.op.t('xshipping.faq.how_to_link_bank_account_for_cod_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_good_is_to_use_shipjung_cod_via_xshipping'),
      a: p.op.t('xshipping.faq.how_good_is_to_use_shipjung_cod_via_xshipping_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_are_cod_fees_calculated'),
      a: p.op.t('xshipping.faq.how_are_cod_fees_calculated_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_to_change_addredd_or_phone'),
      a: p.op.t('xshipping.faq.how_to_change_addredd_or_phone_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_to_check_actual_shipping_cost_or_refund_amount'),
      a: p.op.t('xshipping.faq.how_to_check_actual_shipping_cost_or_refund_amount_answer'),
    },
  ],
  XSHIPPING_AGREEMENT_J_AND_T_X_SHIPJUNG: [
    {
      q: 'เงื่อนไขการใช้บริการ\nJ&T Express x Shipjung',
      a:
        '1. อนุมัติรายการก่อน 14.00 น.เข้ารับภายในวันเดียวกัน อนุมัติหลัง 14.00 น.เข้ารับวันถัดไป' +
        '\n2. รับพัสดุขนาดกล่อง (ก + ย + ส) ไม่เกิน 180 ซม. (ด้านละไม่เกิน 100 ซม.) และน้ำหนักไม่เกิน 20 กก.' +
        '\n3. อัตราค่าบริการ คิดตามปริมาตรกล่อง หรือ น้ำหนัก ถ้าเรทไหนมากกว่าจะคิดจากเรทนั้น' +
        '\n4. วิธีการคำนวณปริมาตรกล่อง (ก x ย x ส) ÷ 6,000=ปริมาตรของกล่องนั้น' +
        '\n5. การเข้ารับและจัดส่งพัสดุในพื้นที่ห่างไกล(หมู่เกาะ,แม่ฮ่องสอน,ยะลา,นราธิวาส และ ปัตตานี) มีค่าบริการเพิ่ม 50 บ./กล่อง' +
        '\n6. หากขนาดและน้ำหนัก ระบุไม่ถูกต้องจะมีการเรียกเก็บค่าขนส่งเพิ่มตามขนาดและน้ำหนักในอัตราค่าส่งที่ถูกต้อง' +
        '\nลูกค้าสามารถแจ้งชิปจังตรวจสอบค่าน้ำหนักเกินได้ไม่เกินภายใน 7 วัน หลังจากที่พัสดุจัดส่งสำเร็จ' +
        '\n7. กรณีสินค้าถูกตีกลับจะมีการเรียกเก็บค่าบริการเท่ากับมูลค่าจัดส่ง' +
        '\n8. ค่าธรรมเนียมเรียกเก็บแทน (cod) 2.5% หรือขั้นต่ำ 25 บ. + VAT 7%' +
        '\n9. มูลค่าเก็บเงินปลายทางไม่เกิน 10,000 บ./รายการ' +
        '\n10. ค่าธรรมเนียมประกันพัสดุ 1% ของมูลค่า สูงสุด 50,000 บ./รายการ' +
        '\n11. ประกันพัสดุเสียหาญ/สูญหาย ไม่เกิน 2,000 บ./รายการ ขนส่งจะรับผิดชอบตามเงื่อนไขเท่านั้น' +
        '\n12. เวลาการจัดส่งพัสดุ พื้นที่กรุงเทพฯ/ปริมณฑล ใช้ระยะเวลาจัดส่งประมาณ 2-3 วันทำการ และพื้นที่ต่างจังหวัดหรือพื้นที่ห่างไกล ประมาณ 3-5 วันทำการ' +
        '\n(ในกรณีไม่ติดปัญหาจัดส่ง เช่น ไม่สามารถติดต่อปลายทางได้, ภัยธรรมชาติ, อุบัติเหตุต่างๆ เป็นต้น)' +
        '\nชิปจังขอสงวนสิทธิในการปรับเปลี่ยนราคาหรือเงื่อนไขต่างๆ โดยไม่ต้องแจ้งล่วงหน้า',
    },
    {
      q: 'รูปแบบจัดส่ง ต้องเลือกอันไหนในออเดอร์',
      a: 'เลือก “J&T Express [นัดรับ]” หรือ “J&T Express [COD นัดรับ]” ในหน้าสร้างหรือแก้ไขออเดอร์',
    },
  ],
  XSHIPPING_AGREEMENT_FLASH_X_SHIPJUNG: [
    {
      q: 'เงื่อนไขการใช้บริการ \nFlash Express x Shipjung',
      a:
        '1. อนุมัติรายการก่อน 14.00 น.เข้ารับภายในวันเดียวกัน อนุมัติหลัง 14.00 น.เข้ารับวันถัดไป' +
        '\n2. รับพัสดุขนาดกล่อง (ก + ย + ส) ไม่เกิน 180 ซม. (ด้านละไม่เกิน 100 ซม.) และน้ำหนักไม่เกิน 20 กก.' +
        '\n3. อัตราค่าบริการ คิดตามปริมาตรกล่อง หรือ น้ำหนัก ถ้าเรทไหนมากกว่าจะคิดจากเรทนั้น' +
        '\n4. วิธีการคำนวณปริมาตรกล่อง (ก x ย x ส) ÷ 6,000=ปริมาตรของกล่องนั้น' +
        '\n5. การเข้ารับและจัดส่งพัสดุในพื้นที่ห่างไกล(หมู่เกาะ,แม่ฮ่องสอน,ยะลา,นราธิวาส และ ปัตตานี) มีค่าบริการเพิ่ม 50 บ./กล่อง' +
        '\n6. หากขนาดและน้ำหนัก ระบุไม่ถูกต้องจะมีการเรียกเก็บค่าขนส่งเพิ่มตามขนาดและน้ำหนักในอัตราค่าส่งที่ถูกต้อง' +
        '\nลูกค้าสามารถแจ้งชิปจังตรวจสอบค่าน้ำหนักเกินได้ไม่เกินภายใน 7 วัน หลังจากที่พัสดุจัดส่งสำเร็จ' +
        '\n7. กรณีสินค้าถูกตีกลับจะมีการเรียกเก็บค่าบริการเท่ากับมูลค่าจัดส่ง' +
        '\n8. ค่าธรรมเนียมเรียกเก็บแทน (cod) 2.5% หรือขั้นต่ำ 25 บ. + VAT 7%' +
        '\n9. มูลค่าเก็บเงินปลายทางไม่เกิน 10,000 บ./รายการ' +
        '\n10. ค่าธรรมเนียมประกันพัสดุ 1% ของมูลค่า สูงสุด 50,000 บ./รายการ' +
        '\n11. ประกันพัสดุเสียหาญ/สูญหาย ไม่เกิน 2,000 บ./รายการ ขนส่งจะรับผิดชอบตามเงื่อนไขเท่านั้น' +
        '\n12. เวลาการจัดส่งพัสดุ พื้นที่กรุงเทพฯ/ปริมณฑล ใช้ระยะเวลาจัดส่งประมาณ 2-3 วันทำการ และพื้นที่ต่างจังหวัดหรือพื้นที่ห่างไกล ประมาณ 3-5 วันทำการ' +
        '\n(ในกรณีไม่ติดปัญหาจัดส่ง เช่น ไม่สามารถติดต่อปลายทางได้, ภัยธรรมชาติ, อุบัติเหตุต่างๆ เป็นต้น)' +
        '\nชิปจังขอสงวนสิทธิในการปรับเปลี่ยนราคาหรือเงื่อนไขต่างๆ โดยไม่ต้องแจ้งล่วงหน้า',
    },
    {
      q: 'รูปแบบจัดส่ง ต้องเลือกอันไหนในออเดอร์',
      a: 'เลือก “Flash Express” หรือ “Flash Express [COD]” ในหน้าสร้างหรือแก้ไขออเดอร์',
    },
  ],
  XSHIPPING_AGREEMENT_NINJA_VAN__X_SHIPJUNG: [
    {
      q: 'เงื่อนไขการใช้บริการ\nNinja Van x Shipjung',
      a:
        '1. ไม่มีขั้นต่ำ ในการเข้ารับสินค้า' +
        '\n2. อนุมัติรายการก่อน 14.00 น. รับสินค้าวันเดียวกัน หากอนุมัติหลัง 14.00 น. เข้ารับสินค้าวันถัดไป' +
        '\n3. อัตราค่าบริการ คิดตามน้ำหนักกล่อง หลังจากแพคใส่สินค้าเรียบร้อยแล้ว' +
        '\n4. หากกรอกน้ำหนักไม่ตรงกับความจริง หรือระบุมาไม่ถูกต้อง จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติมตามน้ำหนักและตามเรทราคาที่ถูกต้อง' +
        '\n5. สามารถเข้ารับสินค้าขนาดกล่อง ( กว้าง + ยาว + สูง ) รวมกันไม่เกิน 180 เซนติเมตร (ขนาดด้านใดด้านหนึ่ง ห้ามเกิน 100 cm.) และ น้ำหนักไม่เกิน 20 กิโลกรัม' +
        '\n6. หากน้ำหนักพัสดุมีขนาดเกินกำหนด หรือระบุมาไม่ถูกต้อง จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติม' +
        '\n7. ในกรณีสินค้ามีการตีคืน จะมีการเรียกเก็บค่าบริการภายหลัง' +
        '\n8. ประกันสินค้าเสียหาย/สูญหาย ไม่เกิน 2,000บาท/ชิ้น และขนส่งจะรับผิดชอบตามเงื่อนไขการรับเคลมสินค้าเท่านั้น' +
        '\n9. ค่าธรรมเนียมเรียกเก็บแทน (cod) อยู่ที่ 2.5% หรือขั้นต่ำ 25 บาท' +
        '\n10. เวลาในการจัดส่งสินค้า พื้นที่กรุงเทพฯ/ปริมณฑล ใช้ระยะเวลาการจัดส่งประมาณ 1-3 วันทำการ และพื้นที่ต่างจังหวัดหรือพื้นที่ห่างไกล ประมาณ 3-5 วันทำการ (เวลาดังกล่าว ในกรณีไม่ติดปัญหาการจัดส่ง เช่น ไม่สามารถติดต่อปลายทางได้, ภัยธรรมชาติ, อุบัติเหตุต่างๆ เป็นต้น)' +
        '\n11. หลังอนุมัติรายการเสร็จ ลูกค้าสามารถเช็คน้ำหนักได้ในวันรุ่งขึ้นเพื่อตรวจสอบความถูกต้อง หากพัสดุไหนที่มีน้ำหนักไม่ตรงกับสินค้า กรุณาแจ้งให้กับทางเราทันที หากเกิน 14 วัน จะไม่สามารถจะไม่สามารถเรียกร้องค่าใช้จ่ายในส่วนที่ต้องชำระได้' +
        '\n12. ชิปจังขอสงวนสิทธิในการปรับเปลี่ยนราคาหรือเงื่อนไขต่างๆ โดยไม่ต้องแจ้งล่วงหน้า',
    },
    {
      q: 'รูปแบบจัดส่ง ต้องเลือกอันไหนในออเดอร์',
      a: 'เลือก “Ninja Van” หรือ “Ninja Van [COD]” ในหน้าสร้างหรือแก้ไขออเดอร์',
    },
  ],
  XSHIPPING_AGREEMENT_SCG_X_SHIPJUNG: [
    {
      q: 'เงื่อนไขการใช้บริการ\nSCG x Shipjung',
      a:
        '1. ไม่มีเรทขั้นต่ำ ในการเข้ารับสินค้า' +
        '\n2. อัตราค่าบริการ คิดค่าจัดส่งตามขนาดกล่องเท่านั้น' +
        '\n3. อนุมัติรายการก่อน 13.00 น. รับสินค้าวันเดียวกัน หากอนุมัติหลัง 13.00 น. เข้ารับสินค้าวันถัดไป' +
        '\n4. สามารถเข้ารับสินค้าขนาดกล่อง ( กว้าง + ยาว + สูง ) ไม่เกิน 200 เซนติเมตรและน้ำหนัก ไม่เกิน 25 กิโลกรัม' +
        '\n5. หากขนาดพัสดุมีขนาดเกินกำหนด หรือระบุมาไม่ถูกต้อง จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติม ตามขนาดกล่องที่ถูกต้องและเรทราคาที่ถูกต้อง' +
        '\n6. ในกรณีสินค้ามีการตีคืน จะมีการเรียกเก็บค่าบริการภายหลัง' +
        '\n7. ประกันสินค้าเสียหาย/สูญหาย ไม่เกิน 2,000บาท/ชิ้น และขนส่งจะรับผิดชอบตามเงื่อนไขการรับเคลมสินค้าเท่านั้น' +
        '\n8. ค่าธรรมเนียมเรียกเก็บแทน (cod) อยู่ที่ 2.5% หรือขั้นต่ำ 35 บาท' +
        '\n9. เวลาในการจัดส่งสินค้า พื้นที่กรุงเทพฯ/ปริมณฑล ใช้ระยะเวลาการจัดส่งประมาณ 1-3 วันทำการ และพื้นที่ต่างจังหวัดหรือพื้นที่ห่างไกล ประมาณ 3-5 วันทำการ (ในกรณีไม่ติดปัญหาการจัดส่ง เช่น ไม่สามารถติดต่อปลายทางได้, ภัยธรรมชาติ, อุบัติเหตุต่างๆ เป็นต้น)' +
        '\n10. หลังอนุมัติรายการเสร็จ ลูกค้าสามารถเช็คน้ำหนัก และ ขนาด ได้ในวันรุ่งขึ้นเพื่อตรวจสอบความถูกต้อง  หากพัสดุไหนที่มีน้ำหนักไม่ตรงกับสินค้า กรุณาแจ้งให้กับทางเราทันที หากเกิน 14 วัน จะไม่สามารถจะไม่สามารถเรียกร้องค่าใช้จ่ายในส่วนที่ต้องชำระได้' +
        '\n11. ชิปจังขอสงวนสิทธิในการปรับเปลี่ยนราคาหรือเงื่อนไขต่างๆ โดยไม่ต้องแจ้งล่วงหน้า',
    },
    {
      q: 'รูปแบบจัดส่ง ต้องเลือกอันไหนในออเดอร์',
      a: 'เลือก “SCG Express” หรือ “SCG Express [COD]” ในหน้าสร้างหรือแก้ไขออเดอร์',
    },
  ],
  XSHIPPING_AGREEMENT_DHL_ECONOMY_X_SHIPJUNG: [
    {
      q: 'เงื่อนไขการใช้บริการ\nDHL Economy x Shipjung',
      a:
        '1. เข้ารับสินค้าได้ตั้งแต่ 1 ชิ้นแรกขึ้นไป ไม่มีขั้นต่ำ' +
        '\n2. การจองรถในการเข้ารับพัสดุ ต้องแจ้งก่อนเวลา 11.00 น. หากแจ้งหลังเวลา 11.00 น. จะเข้ารับในวันถัดไป' +
        '\n3. สามารถเข้ารับสินค้าขนาดกล่อง (กว้าง + ยาว + สูง) รวมกันไม่เกิน 200 เซนติเมตร (ขนาดด้านใดด้านหนึ่ง ห้ามเกิน 120cm.) และ น้ำหนักไม่เกิน 30 กิโลกรัม' +
        '\n4. **ในการใช้งานครั้งแรก กรุณาแจ้งเจ้าหน้าที่เพื่อเพิ่มที่อยู่ต้นทางในการเข้ารับพัสดุของท่าน ล่วงหน้า อย่างน้อย 3-5 วัน**' +
        '\n5. อัตราค่าบริการ คิดตามน้ำหนักกล่อง หลังจากแพคใส่สินค้าเรียบร้อยแล้ว' +
        '\n6. หากกรอกน้ำหนักไม่ตรงกับความจริง หรือระบุมาไม่ถูกต้อง จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติมตามน้ำหนักและตามเรทราคาที่ถูกต้อง' +
        '\n7. ในกรณีสินค้ามีการตีคืน จะมีการเรียกเก็บค่าบริการภายหลัง' +
        '\n8. ประกันสินค้าเสียหาญ/สูญหาย ไม่เกิน 2,000บาท/ชิ้น และขนส่งจะรับผิดชอบตามเงื่อนไขการรับเคลมสินค้าเท่านั้น' +
        '\n9. ค่าธรรมเนียมเรียกเก็บแทน (cod) อยู่ที่ 2.5% หรือขั้นต่ำ 25 บาท' +
        '\n10. ค่าบริการ COD และค่าประกันสินค้า รวม VAT 7%' +
        '\n11. ใช้ระยะเวลาจัดส่งนานกว่า Service Parcel Domestict' +
        '\n12. ชิปจังขอสงวนสิทธิในการปรับเปลี่ยนราคาหรือเงื่อนไขต่างๆ โดยไม่ต้องแจ้งล่วงหน้า',
    },
    {
      q: 'รูปแบบจัดส่ง ต้องเลือกอันไหนในออเดอร์',
      a: 'เลือก “SCG Express” หรือ “SCG Express [COD]” ในหน้าสร้างหรือแก้ไขออเดอร์',
    },
  ],
  XSHIPPING_AGREEMENT_EMS_X_SHIPJUNG: [
    {
      q: 'เงื่อนไขการใช้บริการ\nEMS (Drop-off) x Shipjung',
      a:
        '1. นำกล่องสินค้าหรือพัสดุจัดส่งได้ที่จุดบริการ Drop-off ไปรษณีย์' +
        '\n2. หลังจากอนุมัติรายการเรียบร้อยแล้ว จะต้องนำส่งที่จุดบริการ Drop off ภายใน 7 วัน' +
        '\n3. Max. Dimension: 70 x 60 x 60 cm  // Max. Weight: 20Kg' +
        '\n4. อัตราค่าบริการ คิดตามน้ำหนักกล่อง หลังจากแพคใส่สินค้าเรียบร้อยแล้ว' +
        '\n5. สามารถนำสินค้าไปส่งที่จุดบริการ drop off ได้ ภายในเวลาไม่เกิน 15.30 น. หรือตาม เวลาของแต่ละสาขาไปรษณีย์' +
        '\n6. หากขนาดพัสดุมีขนาดเกินกำหนด หรือระบุมาไม่ถูกต้อง จะมีการเรียกเก็บเงินค่าขนส่งเพิ่มเติม' +
        '\n7. ในกรณีสินค้ามีการตีคืน จะมีการเรียกเก็บค่าบริการตีกลับเพิ่มเติมในภายหลัง' +
        '\n8. ประกันสินค้าเสียหาย/สูญหาย ไม่เกิน 2,000บาท/ชิ้น' +
        '\n9. ค่าธรรมเนียมเรียกเก็บแทน (cod) อยู่ที่ 2.5% หรือขั้นต่ำ 25 บาท' +
        '\n10. ค่าบริการ COD และค่าประกันสินค้า รวม VAT 7%' +
        '\n11. เวลาในการจัดส่งสินค้า พื้นที่กรุงเทพฯ/ปริมณฑล ใช้ระยะเวลาการจัดส่งประมาณ 1-2 วันทำการ และพื้นที่ต่างจังหวัดหรือพื้นที่ห่างไกล ประมาณ 3-5 วันทำการ (ในกรณีไม่ติดปัญหาการจัดส่ง เช่น ไม่สามารถติดต่อปลายทางได้, ภัยธรรมชาติ, อุบัติเหตุต่างๆ เป็นต้น)' +
        '\n12. หลังอนุมัติรายการเสร็จ ลูกค้าสามารถเช็คน้ำหนักได้ในวันรุ่งขึ้นเพื่อตรวจสอบความถูกต้อง หากพัสดุไหนที่มีน้ำหนักไม่ตรงกับสินค้า กรุณาแจ้งให้กับทางเราทันที หากเกิน 14 วัน จะไม่สามารถจะไม่สามารถเรียกร้องค่าใช้จ่ายในส่วนที่ต้องชำระได้' +
        '\n13. ชิปจังขอสงวนสิทธิในการปรับเปลี่ยนราคาหรือเงื่อนไขต่างๆ โดยไม่ต้องแจ้งล่วงหน้า' +
        '\n หมายเหตุเวลา EMS (Drop-off) ของแต่ละไปรษณีย์อาจไม่เท่ากันโปรดเช็คเวลาทำการของแต่ละไปรษณีย์ก่อนเข้ารับการบริการ',
    },
    {
      q: 'รูปแบบจัดส่ง ต้องเลือกอันไหนในออเดอร์',
      a: 'เลือก “ไปรษณีย์ด่วนพิเศษ (EMS)” ในหน้าสร้างหรือแก้ไขออเดอร์',
    },
    {
      q: p.op.t('xshipping.faq.shipping_rate'),
      a: p.op.t('xshipping.faq.shipping_rate_answer'),
    },
    {
      q: 'ส่งแบบเก็บเงินปลายทาง (COD) ได้ไหม',
      a: 'กรุณาใช้การเชื่อมต่อแบบ "ไปรษณีย์ไทย EMS โดยตรง" แทน เนื่องจากการเชื่อมต่อผ่าน Shipjung ไม่รองรับการส่งแบบเก็บเงินปลายทาง',
    },
    {
      q: 'นำส่งพัสดุอย่างไร',
      a:
        'นำส่งเอง ได้ที่ไปรษณีย์สาขาที่มีช่องบริการธุรกิจ (Drop-off) (คุณสามารถสอบถามกับสาขาไปรษณีย์ที่ไปส่งว่ามีช่องบริการธรุกิจ Drop-off หรือไม่)',
    },
    {
      q: p.op.t('xshipping.faq.how_do_enable'),
      a: p.op.t('xshipping.faq.how_do_enable_answer'),
    },
    {
      q: p.op.t('xshipping.faq.enabled_how_to_use'),
      a: p.op.t('xshipping.faq.enabled_how_to_use_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_to_pay_for_shipping'),
      a: p.op.t('xshipping.faq.how_to_pay_for_shipping_answer'),
    },
    {
      q: p.op.t('xshipping.faq.when_is_shipping_credit_cut_off'),
      a: p.op.t('xshipping.faq.when_is_shipping_credit_cut_off_answer'),
    },
    {
      q: p.op.t('xshipping.faq.cancel_shipping_how_to_refund'),
      a: p.op.t('xshipping.faq.cancel_shipping_how_to_refund_answer'),
    },
    {
      q: p.op.t('xshipping.faq.how_to_check_actual_shipping_cost_or_refund_amount'),
      a: p.op.t('xshipping.faq.how_to_check_actual_shipping_cost_or_refund_amount_answer'),
    },
  ],
  XSHIPPING_AGREEMENT_EMS_AGREEMENT: [
    {
      q: 'เรทค่าส่งเท่าไหร่',
      a:
        'ค่าส่งและโปรโมชั่นตามที่คุณตกลงไว้กับ ไปรษณีย์ไทย เลย เช่นถ้ามีดีลพิเศษกับทางไปรษณีย์ไทย หรือทางไปรษณีย์ไทยมีจัดโปรโมชั่นอยู่ ก็สามารถส่งด้วยราคานั้นๆได้เลย',
    },
    {
      q: 'ชำระค่าส่งอย่างไร',
      a: 'ชำระให้กับ ไปรษณีย์ไทย ได้โดยตรงเลย',
    },
    {
      q: 'รับชำระยอดเก็บเงินปลายทางอย่างไร',
      a:
        'รับชำระจาก ไปรษณีย์ไทย ได้โดยตรงเลย โดยเมื่อทาง ไปรษณีย์ไทย โอนเงินเข้าบริการกระเป๋าเงินไปรษณีย์ (Wallet@POST) ของเราแล้ว ระบบ XSelly จะบันทึกรับชำระยอด COD ของแต่ละออเดอร์ให้อัตโนมัติ (ออเดอร์ต่อออเดอร์เลย) ดังนั้นคุณจึงไม่ควรบันทึกรับชำระสำหรับออเดอร์ที่ส่งแบบ COD เอง',
    },
    {
      q: 'เปิดการใช้งานแล้วยังไงต่อ',
      a:
        'เมื่อเปิดการใช้งานสำหรับแต่ละ”รูปแบบจัดส่ง”แล้ว ที่แต่ละออเดอร์ให้เลือกรูปแบบขนส่งเป็น “ไปรษณีย์ด่วนพิเศษ (EMS)” หรือ “ไปรษณีย์ด่วนพิเศษ (EMS) [COD]” จากนั้น ที่แต่ละออเดอร์ คุณสามารถขอเลขพัสดุได้ด้วยขั้นตอนดังนี้' +
        '\n1) ในหน้ารายการ หรือ รายละเอียดออเดอร์ กดปุ่ม “ขอเลขพัสดุทันที”' +
        '\nหรือ' +
        '\n2) เมื่อออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ระบบจะขอเลขพัสดุให้อัตโนมัติ หากคุณเปิดการใช้งาน “ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” ไว้' +
        'สำหรับออเดอร์ที่สร้างไว้ก่อนเปิดการใช้งานฟีเจอร์นี้ คุณสามารถทำตามขั้นตอนที่ 1) เพื่อขอเลขพัสดุแต่ละออเดอร์ได้',
    },
    {
      q: 'นำส่งพัสดุอย่างไร',
      a: 'เมื่อได้เลขติดตามพัสดุแล้ว สามารถไปส่งเองที่ ไปรษณีย์ไทย โดยหากต้องการให้พนักงานไปรษณีย์ไทย เข้ารับให้จะต้องตกลงกับทางสาขาเอง',
    },
  ],
  XSHIPPING_AGREEMENT_ECOPOST_AGREEMENT: [
    {
      q: 'เรทค่าส่งเท่าไหร่',
      a:
        'ค่าส่งและโปรโมชั่นตามที่คุณตกลงไว้กับ ไปรษณีย์ไทย เลย เช่นถ้ามีดีลพิเศษกับทางไปรษณีย์ไทย หรือทางไปรษณีย์ไทยมีจัดโปรโมชั่นอยู่ ก็สามารถส่งด้วยราคานั้นๆได้เลย',
    },
    {
      q: 'ชำระค่าส่งอย่างไร',
      a: 'ชำระให้กับ ไปรษณีย์ไทย ได้โดยตรงเลย',
    },
    {
      q: 'รับชำระยอดเก็บเงินปลายทางอย่างไร',
      a:
        'รับชำระจาก ไปรษณีย์ไทย ได้โดยตรงเลย โดยเมื่อทาง ไปรษณีย์ไทย โอนเงินเข้าบริการกระเป๋าเงินไปรษณีย์ (Wallet@POST) ของเราแล้ว ระบบ XSelly จะบันทึกรับชำระยอด COD ของแต่ละออเดอร์ให้อัตโนมัติ (ออเดอร์ต่อออเดอร์เลย) ดังนั้นคุณจึงไม่ควรบันทึกรับชำระสำหรับออเดอร์ที่ส่งแบบ COD เอง',
    },
    {
      q: 'เปิดการใช้งานแล้วยังไงต่อ',
      a:
        'เมื่อเปิดการใช้งานสำหรับแต่ละ”รูปแบบจัดส่ง”แล้ว ที่แต่ละออเดอร์ให้เลือกรูปแบบขนส่งเป็น “ไปรษณีย์ eco-Post” หรือ “ไปรษณีย์ eco-Post [COD]” จากนั้น ที่แต่ละออเดอร์ คุณสามารถขอเลขพัสดุได้ด้วยขั้นตอนดังนี้' +
        '\n1) ในหน้ารายการ หรือ รายละเอียดออเดอร์ กดปุ่ม “ขอเลขพัสดุทันที”' +
        '\nหรือ' +
        '\n2) เมื่อออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ระบบจะขอเลขพัสดุให้อัตโนมัติ หากคุณเปิดการใช้งาน “ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” ไว้' +
        'สำหรับออเดอร์ที่สร้างไว้ก่อนเปิดการใช้งานฟีเจอร์นี้ คุณสามารถทำตามขั้นตอนที่ 1) เพื่อขอเลขพัสดุแต่ละออเดอร์ได้',
    },
    {
      q: 'นำส่งพัสดุอย่างไร',
      a: 'เมื่อได้เลขติดตามพัสดุแล้ว สามารถไปส่งเองที่ ไปรษณีย์ไทย โดยหากต้องการให้พนักงานไปรษณีย์ไทย เข้ารับให้จะต้องตกลงกับทางสาขาเอง',
    },
  ],
  XSHIPPING_BEST_AGREEMENT: [
    {
      q: 'เรทค่าส่งเท่าไหร่',
      a:
        'ค่าส่งและโปรโมชั่นจะเป็นไปตาม BEST เลย โดยหากมีเรทพิเศษที่คุยไว้กับทางสาขาหรือเซลล์อยู่แล้ว แนะนำให้สอบถามไปทางสาขาหรือเซลล์เพื่อยืนยันก่อนว่า *เรทค่าส่งจะยังได้เท่าเดิมเมื่อออกเลขพัสดุผ่าน XSelly*',
    },
    {
      q: 'ชำระค่าส่งอย่างไร',
      a: 'ชำระให้กับ BEST โดยตรงได้เลย',
    },
    {
      q: 'ตั้งค่าและรับชำระยอดเก็บเงินปลายทางอย่างไร',
      a:
        'รับชำระยอด COD จาก BEST โดยตรงได้เลย โดย BEST จะโอนเงินไปที่บัญชีธนาคารที่ระบุไว้ใน XSelly ซึ่งมีขั้นตอนดังนี้' +
        '\n1) กดปุ่ม "บัญชีรับชำระ" ในหน้า "ตั้งค่า" ของร้าน' +
        '\n2) กดปุ่ม "+" เพื่อสร้างบัญชีรับชำระ (ถ้ายังไม่มี) โดยธนาคารที่ BEST รองรับมีดังนี้' +
        '\n • ธนาคารกรุงเทพ (BBL)' +
        '\n • ธนาคารกสิกรไทย (KBank)' +
        '\n • ธนาคารกรุงไทย (KTB)' +
        '\n • ธนาคารไทยพาณิชย์ (SCB)' +
        '\n • ธนาคารทีเอ็มบีธนชาต (ttb)' +
        '\n • ธนาคารซิตี้แบงก์ (Citibank)' +
        '\n • ธนาคารอิสลามแห่งประเทศไทย (IBank)' +
        '\n3)f แจ้งทีมงาน XSelly ให้เปิดการใช้งานการเก็บเงินปลายทางกับ BEST ได้ทาง LINE: @XSelly หรือ โทร 097-167-1650 ได้ทุกวันไม่มีวันหยุด',
    },
    {
      q: 'นำส่งพัสดุอย่างไร',
      a:
        'ไปส่งเองที่สาขาของ BEST หรือให้ทางสาขาเข้ารับโดยระบบจะนัดหมายสาขาตามที่อยู่ผู้จัดส่ง(ที่ระบุในออเดอร์)ที่ใกล้ที่สุดในการเข้ารับโดยอัตโนมัติ\n\nในการให้เข้ารับครั้งแรกหลังจากได้เลขติดตามพัสดุแล้ว แนะนำให้ติดต่อไปที่สาขา BEST ที่ใกล้ที่สุดเพื่อประสานงานและยืนยันการเข้ารับก่อน',
    },
    {
      q: 'เปิดการใช้งานแล้วยังไงต่อ',
      a:
        'เมื่อเปิดการใช้งานสำหรับแต่ละ "รูปแบบจัดส่ง" แล้ว ที่แต่ละออเดอร์ให้เลือกรูปแบบขนส่งเป็น\n"BEST Express" หรือ "BEST Express [COD]" ตามที่ได้เปิดการใช้งานไว้ จากนั้น ที่แต่ละออเดอร์ คุณสามารถขอเลขพัสดุได้ด้วยขั้นตอนดังนี้' +
        '\n1) ในหน้ารายการ หรือ รายละเอียดออเดอร์ กดปุ่ม "ขอเลขพัสดุทันที"' +
        '\nหรือ' +
        '\n2) เมื่อออเดอร์เข้าสู่สถานะ “รอฉัน > จัดส่ง” ระบบจะขอเลขพัสดุให้อัตโนมัติ หากคุณเปิดการใช้งาน “ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง” ไว้' +
        '\nสำหรับออเดอร์ที่สร้างไว้ก่อนเปิดการใช้งานฟีเจอร์นี้ คุณสามารถทำตามข้อ 1) เพื่อขอเลขพัสดุแต่ละออเดอร์ได้',
    },
    {
      q: 'ที่อยู่ผู้ส่ง สำคัญไฉน',
      a:
        'เมื่อมีการขอเลขพัสดุ XSelly จะส่งข้อมูลชื่อ ที่อยู่ และเบอร์โทรของทั้ง “ผู้ส่ง และ ผู้รับ” ตามที่ระบุไว้ในแต่ละออเดอร์ไปให้ทาง BEST โดยที่อยู่ผู้ส่งจะถูกใช้ในการแจ้งสาขาที่ใกล้ที่สุดในกรณีที่มีการเข้ารับสินค้า ดังนั้น *จึงจำเป็นที่จะต้องกรอกข้อมูล ผู้ส่งและผู้รับ ให้ครบถ้วน ถูกต้อง*',
    },
    {
      q: 'เลขติดตามพัสดุมีหมดอายุไหม',
      a:
        'คุณควรนำส่งให้ BEST สแกนเลขพัสดุนี้ภายใน 1-3 วัน นับจากวันที่ขอ โดยทางสาขาจะโทรติดต่อเพื่อเข้ารับ หากต้องการเวลาเกินกว่านั้น คุณสามารถปรึกษากับทางสาขาได้',
    },
  ],
  PEAK_FAQ: [
    {
      q: 'ราคาก่อนส่วนลด',
      a:
        'ในหน้าสร้างออเดอร์ของ XSelly หากมีการปรับลดราคาขายของสินค้าแต่ละตัว และต้องการให้ส่งข้อมูลไปที่ PEAK แบบแยกส่วนลด คุณสามารถระบุ' +
        '\n\n"ราคาก่อนส่วนลด"' +
        '\n\nเพื่อใช้คำนวณส่วนลดของแต่ละสินค้าได้' +
        '\n\nตัวอย่างเช่น ถ้าราคาสินค้าตามที่ระบุไว้สำหรับ "ราคาก่อนส่วนลด" คือ 100 บาท และในหน้าสร้างออเดอร์ได้แก้ไขให้ราคาสินค้าต่อชิ้นเหลือ 80 บาท' +
        '\n\nข้อมูลที่ส่งไปให้ PEAK จะเป็น ราคาสินค้าชิ้นละ 100 บาท และส่วนลดสินค้าชิ้นละ 20 บาท' +
        '\n\nในทางกลับกัน หากเลือก "ไม่ระบุ" ราคาก่อนส่วนลด ข้อมูลที่ส่งไปให้ PEAK จะเป็น ราคาสินค้าชิ้่นละ 80 บาท และส่วนลด 0 บาท',
    },
  ],
  PEAK_REPORT_FAQ: [
    {
      q: 'รายงาน Excel นี้ใช้ทำอะไร',
      a:
        'คุณสามารถดาวน์โหลด รายงาน Excel นี้ เพื่อนำข้อมูลการขาย (ออเดอร์) ออกจาก XSelly แล้วไปที่โปรแกรมบัญชี PEAK Account เพื่อนำเข้าข้อมูลการขายเข้าสู่ PEAK Account และดำเนินการอื่นๆที่ต้องการต่อ เช่น ออกใบเสร็จรับเงิน ใบกำกับภาษี ดูรายงานทางบัญชี เป็นต้น',
    },
    {
      q: 'รหัสบัญชี คืออะไร',
      a:
        'รหัสบัญชี คือ เลขรหัสบัญชีสำหรับรับชำระจากลูกค้า ที่ระบุอยู่ในโปรแกรมบัญชี PEAK Account เช่น “ธนาคารไทยพาณิชย์ รหัสบัญชี 401101” โดยอาจมีรหัสบัญชีย่อย เพิ่มเติมได้ด้วย ซึ่งคุณสามารถตั้งค่ารหัสบัญชีนี้ใน XSelly ให้ตรงกัน เพื่อที่จะได้แสดงข้อมูลนี้เมื่อ นำออกข้อมูล (Export data) ออกจาก XSelly ได้',
    },
    {
      q: 'รหัสบัญชี ตั้งค่าอย่างไร',
      a:
        'ไปที่ หน้าแรก > ตั้งค่าร้าน (รูปเฟื่องด้านขวาบน) > บัญชีรับชำระ > เลือกบัญชีที่ต้องการแก้ไข > เปิดสวิตซ์ “ตั้งค่าเพิ่มเติมสำหรับ PEAK Account” > ระบุ รหัสบัญชี > กด บันทึก',
    },
    {
      q: 'รหัสบัญชีสำหรับค่าขนส่ง คืออะไร',
      a:
        'รหัสบัญชีสำหรับค่าขนส่ง คือ รหัสบัญชีที่ใช้สำหรับค่าขนส่งโดยเฉพาะ ซึ่งใช้ในกรณีที่มีการแยกบัญชีค่าส่งไว้ในโปรแกรม PEAK Account เอาไว้แล้ว โดยหากไม่ได้แยกไว้ก็ไม่ต้องระบุรหัสบัญชีสำหรับค่าขนส่ง และเมื่อนำออกข้อมูลจาก XSelly ค่าขนส่งก็จะใช้ รหัสบัญชีเดียวกับของออเดอร์',
    },
    {
      q: 'รหัสบัญชีสำหรับค่าขนส่ง และข้อมูลอื่นๆ ตั้งค่าอย่างไร',
      a: 'ไปที่ หน้าแรก > ตั้งค่าร้าน (รูปเฟื่องด้านขวาบน) > PEAK Account',
    },
  ],
  ORDER_VISIBLE_ONLY_PROFILE_ID: [
    {
      q: 'จำกัดให้เห็นออเดอร์ได้เฉพาะที่ผู้ช่วยสร้างเองเท่านั้น คืออะไร',
      a:
        'เมื่อติ๊กเลือกสิทธิ์นี้ ผู้ช่วยจะถูกจำกัดสิทธิ์ให้เห็นข้อมูลออเดอร์เฉพาะที่ตนสร้างเองเท่านั้น ซึ่งจะมีผลกับการแสดงข้อมูลเหล่านี้' +
        '\n • รายการออเดอร์' +
        '\n • รายละเอียดออเดอร์' +
        '\n • จำนวนรวมออเดอร์' +
        '\n • การค้นหาออเดอร์' +
        '\n • การสแกนจัดส่งออเดอร์' +
        '\n • รายงาน ยอดขายผู้ใช้งานและผู้ช่วย' +
        '\n\nโดยการจำกัดสิทธิ์นี้ไม่ได้รวมไปถึงข้อมูลออเดอร์ในการใช้งานส่วนอื่น เช่น การพิมพ์ใบปะหน้าพัสดุ และรายงานตัวอื่นๆ',
    },
  ],
  // MKP CHANNEL
  PG_MKP_CHANNEL: [
    {
      q: 'ราคาสำหรับสินค้าสร้างใหม่ คือ อะไร',
      a:
        'คือราคาที่จะถูกระบุให้สินค้าที่จะถูกสร้างใหม่ใน XSelly ระหว่างการซิงค์ข้อมูลสินค้ากับช่องทางขาย โดยจะมีมูลค่าเท่ากับที่ระบุไว้ในช่องทางขาย',
    },
    {
      q: 'สินค้าจะถูกสร้างใหม่เมื่อไหร่',
      a:
        'ถูกสร้างในระหว่างการซิงค์ข้อมูลสินค้าบนช่องทางขายที่ยังไม่เคยเชื่อมกับสินค้าใน XSelly และ SKU ของสินค้าบนช่องทางขายไม่ตรงกับใน XSelly',
    },
    {
      q: 'ราคาที่ถูกเปลี่ยนบนช่องทางขายจะอัพเดทมาที่ XSelly หรือไม่',
      a:
        'ไม่! เนื่องจากราคาบนช่องทางขายจะถูกนำมาใช้เพียงครั้งเดียว ซึ่งคือครั้งแรกที่สินค้ามีการสร้างใหม่ให้ XSelly ในระหว่างการซิงค์ข้อมูลสินค้า',
    },
    {
      q: 'การเปลี่ยนแปลงรายการราคาใน XSelly หรือบนช่องทางขาย มีผลกับสินค้าที่ผูกสต๊อกกันอยู่ในแล้วใน XSelly หรือไม่?',
      a: 'ไม่มี',
    },
    {
      q: 'ถ้าสินค้าบนช่องทางขายเชื่อมกับสินค้าบน XSelly อยู่แล้ว ระบบจะสร้างราคาใหม่ในระหว่างการซิงค์หรือไม่?',
      a: 'ไม่สร้าง และไม่แก้ไข! ระบบจะใช้ราคาเดิมตามที่มีอยู่แล้วใน XSelly',
    },
    {
      q: 'สามารถเปลี่ยนราคาและมูลค่าของราคาของสินค้าหลังจากที่เชื่อมต่อกับข่องทางขาย ได้หรือไม่',
      a:
        'ได้ สามารถปรับเปลี่ยนได้ตามต้องการ เนื่องจากราคาที่ผูกอยู่และมูลค่าที่ระบุไว้ไม่มีผลกับการเชื่อมต่อสินค้าเพื่อซิงค์สต๊อกใน XSelly',
    },
  ],
  MKP_AUTO_COMFIRM_ORDER: [
    {
      q: 'ฟีเจอร์นี้มีดีอย่างไร',
      a:
        'เมื่อมีลูกค้าเปิดออเดอร์มาทางช่องทางขาย โดยปกติแล้วเราต้องเข้าไปที่ระบบหลังบ้าน (Seller Center) ของช่องทางขายเพื่อไปกดยืนยันออเดอร์ (คำสั่งซื้อ) เพื่อแจ้งว่าเราจะจัดส่งและขอรับเลขพัสดุ ฟีเจอร์นี้จะช่วยให้คุณสะดวกโดยการยืนยันออเดอร์ให้อัตโนมัติ ทำให้คุณไม่ต้องเข้าไปที่ระบบหลังบ้านของช่องทางขาย และลดช่วงเวลาที่ลูกค้าอาจกดยกเลิกออเดอร์ได้',
    },
    {
      q: 'Pickup กับ Dropoff ต่างกันอย่างไร',
      a:
        'Pickup คือการแจ้งให้ทางขนส่งเข้ามารับพัสดุตามที่อยู่ที่ระบุไว้ในช่องทางขาย ส่วน Dropoff คือการที่เรานำพัสดุไปส่งเองตามจุด drop off เช่นสาขาของขนส่ง',
    },
  ],
  MKP_CREATE_ORDER_AT_STATUS: [
    {
      q: 'ตั้งค่านี้มีผลอย่างไหร่',
      a:
        'เมื่อมีลูกค้าสั่งซื้อมาจากช่องทางขาย ลูกค้าอาจจะ*ชำระ*หรือ*ยังไม่ชำระ* การตั้งค่านี้จะช่วยให้คุณเลือกได้ว่าจะให้ สร้างออเดอร์ใน XSelly ก่อนหรือหลังลูกค้าชำระในช่องทางขาย',
    },
    {
      q: 'เมื่อไหร่ควรเลือก "ก่อนชำระ',
      a:
        'เมื่อคุณต้องการให้มีการอัพเดทออเดอร์และสต๊อกของสินค้าทันทีที่มีการสร้างออเดอร์เข้ามา เพื่อลดโอกาสการสั่งสินค้าเกินสต๊อกจากช่องทางอื่น เช่น สมมติว่า ร้านมีสินค้า A เหลือเพียง 1 ชิ้น และมีการขายผ่าน Shopee Lazada และ แชท เมื่อมีการเปิดออเดอร์จากทาง Shopee แต่ยังไม่ชำระ XSelly จะดูดออเดอร์และปรับลดจำนวน "พร้อมขาย" ให้เลือก 0 ในทุกๆช่องทาง ทำให้ลูกค้าจากช่องทาง Lazada หรือ แชท ไม่สามารถเปิดออเดอร์มาได้ ป้องกันปัญหาไม่มีสินค้าให้จัดส่ง\nหากลูกค้ายกเลิกออเดอร์ หรือไม่ชำระในเวลาที่กำหนด X',
    },
    {
      q: 'เมื่อไหร่ควรเลือก "หลังชำระ"',
      a: 'เมื่อคุณมีจำนวนสินค้ามากพอจัดส่ง และต้องการให้ XSelly สร้างออเดอร์เฉพาะที่ลูกค้าชำระมาแล้ว',
    },
  ],
  MKP_IS_SELF_DELIVERY: [
    {
      q: 'การตั้งค่านี้มีไว้เพื่ออะไร',
      a: 'เพื่อกำหนดได้ว่า เลขพัสดุที่ใช้ในการจัดส่งจะมาจากช่องทางขายหรือไม่',
    },
    {
      q: 'เมื่อไหร่ควร *ใช้*',
      a: 'เมื่อทางช่องทางขายกำหนดเลขพัสดุมาให้',
    },
    {
      q: 'เมื่อไหร่ควร *ไม่ใช้*',
      a:
        'เมื่อทางช่องทางขายอนุญาตให้คุณสามารถระบุเลขพัสดุได้เอง เช่น เป็นพัสดุขนาดใหญ่ หรือ ต้องแบ่งเป็นหลายพัสดุต่อ 1 ออเดอร์' +
        '\n\nหากคุณเลือกการตั้งค่านี้ คุณสามารถใช้ฟีเจอร์อื่นๆเพิ่มเติมได้ดังนี้' +
        '\n1. ฟีเจอร์ XShipping ในการออกเลขพัสดุและแจ้งเลขพัสดุกลับไปที่ช่องทางขายได้อัตโนมัติ ' +
        '\n2. ฟีเจอร์ *่แบ่งส่งหลายพัสดุ* เพื่อแบ่งเป็นการจัดส่งของ 1 ออเดอร์ให้เป็นหลายพัสดุและสามารถออกเลขติดตามแต่ละพัสดุแยกกันได้อีกด้วย',
    },
  ],
  MKP_SELF_DELIVERY_AUTO_CONFIRM_TRACKING_NUMBER: [
    {
      q: 'การตั้งค่านี้มีไว้เพื่ออะไร',
      a:
        'เพื่อกำหนดได้ว่า เมื่อออเดอร์ที่มาจากช่องทางขายนี้มีการบันทึกจัดส่งใน XSelly ให้ระบบทำการแจ้งบันทึกจัดส่งพร้อมเลขพัสดุนี้ ' +
        'ไปที่ช่องทางขายให้โดยอัตโนมัติหรือไม่\n\nดังนั้นหากคุณ*เปิด*การใช้งาน ระบบก็แจ้งข้อมูลไปที่ช่องทางขายให้โดยอัตโนมัติ และคุณก็ไม่ต้องไปแจ้งเองที่ Seller Center ของช่องทางขาย ' +
        'ให้ระบบช่วยเพิ่มความเร็วในการทำงาน',
    },
  ],
  MKP_SELF_DELIVERY_USE_XSHIPPING: [
    {
      q: 'การตั้งค่านี้มีไว้เพื่ออะไร',
      a: 'เมื่อ*เปิด*การใช้งาน XSelly จะออกเลขพัสดุ XShipping ตามที่ตั้งค่าไว้ ให้โดยอัตโนมัติเมื่อมีออเดอร์สร้างใหม่มาจากช่องทางขาย',
    },
  ],
  MKP_SELF_DELIVERY_DEFAULT_SHIPPING_TYPE_NON_COD: [
    {
      q: 'การตั้งค่านี้มีไว้เพื่ออะไร',
      a:
        'เพื่อกำหนดค่าเริ่มต้นของรูปแบบจัดส่งสำหรับออเดอร์ที่*ไม่เก็บ*เงินปลายทาง โดยค่านี้จะถูกใช้เมื่อระบบไม่สามารถแปลค่ารูปแบบจัดส่งที่มาจากช่องทางขายได้',
    },
  ],
  MKP_SELF_DELIVERY_DEFAULT_SHIPPING_TYPE_COD: [
    {
      q: 'การตั้งค่านี้มีไว้เพื่ออะไร',
      a:
        'เพื่อกำหนดค่าเริ่มต้นของรูปแบบจัดส่งสำหรับออเดอร์ที่*เก็บ*เงินปลายทาง โดยค่านี้จะถูกใช้เมื่อระบบไม่สามารถแปลค่ารูปแบบจัดส่งที่มาจากช่องทางขายได้',
    },
  ],
  MKP_SELF_DELIVERY_SENDER_ADDRESS: [
    {
      q: 'การตั้งค่านี้มีไว้เพื่ออะไร',
      a:
        'เมื่อระบุ XSelly จะสร้างออเดอร์ที่มาจากช่องทางขายโดยระบุที่อยู่ผู้ส่งตามที่ระบุไว้ในตั้งค่านี้' +
        '\n\nซึ่งที่อยู่ผู้ส่งนี้จะถูกนำไปแสดงบนใบปะหน้าพัสดุและใช้ในการขอเลขพัสดุ XShipping (หากเปิดการใช้งาน) ',
    },
  ],
  MKP_SYNC_MODE_ORDER: [
    {
      q: 'การตั้งค่านี้มีไว้เพื่ออะไร',
      a: 'เพื่อกำหนดได้ว่า ข้อมูลออเดอร์จากช่องทางขายควรถูกนำมาบันทึกและแสดงใน XSelly หรือไม่',
    },
    {
      q: 'เมื่อไหร่ควรเลือก "ซิงค์เข้า XSelly"',
      a:
        'เมื่อคุณต้องการให้ออเดอร์ที่ถูกสร้างบนช่องทางขาย ถูกดูดมาสร้างใน XSelly ด้วย เพื่อประโยชน์ต่างๆ เช่น\n' +
        '1) บริหารจัดการออเดอร์ง่ายในที่เดียว\n' +
        '2) ตัดสต๊อกอัตโนมัติ และอัพเดทสต๊อกไปที่ช่องทางอื่นๆ\n' +
        '3) พิมพ์ใบปะหน้าพัสดุรวมจากช่องทางอื่นได้เลยใน XSelly\n' +
        '4) ดูรายงานการขาย สต๊อกและอื่นๆ\n' +
        '5) สะดวกด้วยฟีเจอร์ต่างๆ เช่น ยืนยันออเดอร์อัตโนมัติ',
    },
    {
      q: 'เมื่อไหร่ควรเลือก "ไม่ซิงค์เข้า XSelly"',
      a:
        'เมื่อคุณต้องการใช้ XSelly ในการซิงค์เฉพาะข้อมูลสินค้าและสต๊อกเป็นครั้งคราว ' +
        'เช่น สร้างรายการสินค้าใน XSelly แต่ไม่ต้องการบริหารออเดอร์และจำนวนสต๊อกใน XSelly\n\nเพราะเมื่อออเดอร์ไม่ถูกสร้างใน XSelly ก็จะไม่มีการลดสต๊อกสินค้าในออเดอร์นั้นๆตามไปด้วย',
    },
  ],
  MKP_AUTO_LOAD_SHIPPING_LABEL: [
    {
      q: 'ฟีเจอร์นี้มีไว้ทำอะไร',
      a:
        'เมื่อเลือก "โหลดอัตโนมัติ" XSelly จะโหลดใบปะหน้าพัสดุจากช่องทางขายให้อัตโนมัติ ทำให้สามารถพิมพ์ใบปะหน้าจากการขายทุกช่องทางได้ง่าย สะดวก ในที่เดียว',
    },
  ],
  MKP_SYNC_MODE_QTY: [
    {
      q: 'ฟีเจอร์นี้มีไว้ทำอะไร',
      a:
        'หากเลือก "จาก XSelly ไปทับที่ช่องทางขาย" จำนวน "พร้อมขาย" ของแต่ละสินค้าใน XSelly จะถูกส่งไปทับจำนวนคลังในช่องทางขายเมื่อมีการเปลี่ยนแปลงใน XSelly',
    },
    {
      q: 'เมื่อไหร่ควรเลือก "ไม่อัพเดทระหว่างกัน"',
      a:
        'เมื่อคุณไม่ต้องการให้มีการอัพเดทจำนวน "พร้อมขาย" ของแต่ละสินค้าใน XSelly ไปอัพเดทบนช่องทางขาย ซึ่งคุณอาจใช้ในกรณีที่ต้องการใช้ XSelly เพียงเพื่อทุ่นแรงการสร้างสินค้าใน XSelly ครั้งแรก โดยดูดข้อมูลสินค้าจากทางช่องทางขายลงมา หรืออาจจะใช้ในกรณีที่มีการจัดโปรโมชั่น และคาดว่าจะมีออเดอร์เปิดเข้ามาในปริมาณมากในเวลาอันสั้น เช่น 8.8 หรือ 9.9 แล้วต้องการให้ใช้ตัวเลขคลังที่อยู่บนช่องทางขายเป็นหลัก จนกว่าจะหมดช่วงเวลาโปรโมชั่น',
    },
  ],
  MKP_SYNC_PRODUCT: [
    {
      q: 'การซิงค์ข้อมูลสินค้าจากช่องทางขายคืออะไร',
      a:
        'คือการโหลดข้อมูลสินค้าจากช่องทางขายแล้วนำมาผูกกับข้อมูลสินค้าใน XSelly เพื่อความสะดวกในการบริหารจัดการสต๊อกสินค้า ออเดอร์ และรายงานการขาย',
    },
    {
      q: 'การซิงค์ข้อมูลสินค้าทำงานอย่างไร',
      a:
        'การซิงค์แบ่งเป็น 2 ขั้นตอนหลักๆ ดังนี้\n\n' +
        '1) การผูกสินค้ากับ XSelly (สำหรับสินค้าที่ไม่เคยผูกกับสินค้าใน XSelly มาก่อน)\n\n' +
        '2) การอัพเดทสต็อก (จำนวนพร้อมขาย) จาก XSelly ไปทับที่ช่องทางขาย (สำหรับสินค้าที่เพิ่งถูกผูกใหม่และที่ผูกกับสินค้าใน XSelly ไว้แล้ว) ซึ่งคุณสามารถปิดการทำงานส่วนนี้ได้ที่ตั้งค่าช่องทางขายหากต้องการ',
    },
    {
      q: 'การผูกสินค้ากับ XSelly ทำงานอย่างไร',
      a:
        'ในระหว่างการซิงค์ สำหรับสินค้าที่ยังไม่เคยผูกกับ XSelly มาก่อน จะถูกดำเนินการดังนี้\n\n' +
        '1) สินค้าบนช่องทางขาย "ที่ไม่มี SKU": ระบบจะสร้างสินค้าให้ใหม่ใน XSelly และทำการผูกสินค้ากัน\n\n' +
        '2) สินค้าบนช่องทางขาย "ที่มี SKU": ระบบจะหาสินค้าใน XSelly ที่มี SKU ตรงกันและผูกสินค้าให้ แต่หากไม่พบสินค้าใน XSelly ที่มี SKU ตรงกัน ระบบจะสร้างสินค้าใน XSelly ให้ใหม่และผูกกัน โดยในกรณีที่มีการเพิ่มตัวเลือกสินค้าจากช่องทางขาย เช่น สี ขนาด ลาย ไปบนสินค้าที่ผูกกันไว้อยู่แล้ว ระบบจะเพิ่มตัวเลือกสินค้าใหม่นี้ใน XSelly หากตัวเลือกในสินค้าจากช่องทางไม่ได้ผูกอยู่กับสินค้าใน XSelly ตัวอื่นด้วย',
    },
    {
      q: 'ตัวเลือกการซิงค์ "จากอัพเดทล่าสุด" คืออะไร',
      a:
        'เป็นตัวเลือกที่ทำงานได้เร็วและกดได้บ่อยเท่าที่ต้องการ โดยเป็นการโหลดข้อมูลสินค้าจากช่องทางขายที่มีการเปลี่ยนแปลงเมื่อเทียบกับเวลาที่ซิงค์ครั้งสุดท้าย คุณอาจกดใช้ตัวเลือกนี้เมื่อมีการสร้างสินค้าใหม่บนช่องทางขาย และต้องการให้ระบบดึงข้อมูลมาที่ XSelly ทันที (หากไม่กดซิงค์ ระบบก็จะดึงข้อมูลสินค้าให้อัตโนมัติเมื่อมีออเดอร์ใหม่ที่มีสินค้าตัวนี้)',
    },
    {
      q: 'ตัวเลือกการซิงค์ "ทั้งหมด" คืออะไร',
      a:
        'เป็นตัวเลือกที่แจ้งให้ระบบทำการโหลดข้อมูลสินค้าทั้งร้านจากช่องทางขาย เนื่องจากข้อมูลมีปริมาณมากกว่าแบบตัวเลือก "จากอัพเดทล่าสุด" จึงแนะนำให้ใช้ตัวเลือกนี้กรณีที่เลือกแบบ "จากอัพเดทล่าสุด" แล้วแต่ยังไม่เห็นข้อมูลสินค้าที่ต้องการใน XSelly',
    },
  ],
  MKP_SELECTED_PRODUCT_GROUP: [
    {
      q: 'ฟีเจอร์นี้มีไว้ทำอะไร',
      a:
        'หากเลือก "จาก XSelly ไปทับที่ช่องทางขาย" จำนวน "พร้อมขาย" ของแต่ละสินค้าใน XSelly จะถูกส่งไปทับจำนวนคลังในช่องทางขายเมื่อมีการเปลี่ยนแปลงใน XSelly',
    },
    {
      q: 'เมื่อไหร่ควรเลือก "ไม่อัพเดทระหว่างกัน"',
      a:
        'เมื่อคุณไม่ต้องการให้มีการอัพเดทจำนวน "พร้อมขาย" ของแต่ละสินค้าใน XSelly ไปอัพเดทบนช่องทางขาย ซึ่งคุณอาจใช้ในกรณีที่ต้องการใช้ XSelly เพียงเพื่อทุ่นแรงการสร้างสินค้าใน XSelly ครั้งแรก โดยดูดข้อมูลสินค้าจากทางช่องทางขายลงมา หรืออาจจะใช้ในกรณีที่มีการจัดโปรโมชั่น และคาดว่าจะมีออเดอร์เปิดเข้ามาในปริมาณมากในเวลาอันสั้น เช่น 8.8 หรือ 9.9 แล้วต้องการให้ใช้ตัวเลขคลังที่อยู่บนช่องทางขายเป็นหลัก จนกว่าจะหมดช่วงเวลาโปรโมชั่น',
    },
  ],
  MKP_PRODUCT_GROUP: [
    {
      q: 'รายการราคาใน XSelly คืออะไร',
      a:
        'ใน XSelly คุณสามารถเพิ่มรายการราคาได้ (จำนวนตามแพ็กเกจ) ซึ่งใช้ในกรณีที่สินค้ามีการขายได้หลายราคาเช่น ราคาปลีก ราคาตัวแทน และราคาส่ง เป็นต้น ซึ่งสินค้าทุกรายการใน XSelly จะต้องมีอย่างน้อย 1 ราคา',
    },
    {
      q: 'รายการราคาสำหรับสินค้าสร้างใหม่ คืออะไร',
      a:
        'คือราคาที่จะถูกระบุให้สินค้าใน XSelly ที่ถูกสร้างใหม่ระหว่างการซิงค์กับทางช่องทางขาย โดยจะมีค่าเท่ากับราคาที่ระบุไว้ในช่องทางขาย ',
    },
    {
      q: 'เมื่อไหร่ที่ สินค้าใน XSelly จะถูกสร้างใหม่ระหว่างซิงค์',
      a:
        'การสร้างสินค้าใหม่นี้จะเกิดขึ้นทุกครั้งที่ซิงค์สินค้าและพบว่าสินค้าบนช่องทางขายยังไม่เคยมีการผูกกับสินค้าใน XSelly มาก่อนและไม่พบ SKU ที่ตรงกันกับสินค้าที่มีอยู่แล้วใน XSelly',
    },
    {
      q: 'แก้ไขราคาที่ถูกระบุหลังการซิงค์ได้หรือไม่',
      a:
        'ได้! คุณสามารถแก้ไขราคาของแต่ละสินค้าได้ที่หน้า รายละเอียดสินค้าของ XSelly โดยการแก้ไขราคานี้จะมีผลแค่การใช้งานใน XSelly ซึ่งคือจะ*ไม่มี*ผลไปยังบนช่องทางขาย เนื่องจาก XSelly ไม่ได้ซิงค์ราคาสินค้ากับทางช่องขาย',
    },
  ],
  MKP_CANCEL_SHIPPING_WHEN_CANCEL_ORDER: [
    {
      q: 'ตั้งค่าช่องทางขาย ยกเลิกการจัดส่งออเดอร์อัตโนมัติ',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/switch-cancel-order.png',
          t: 'ตั้งค่าช่องทางขาย ยกเลิกการจัดส่งออเดอร์อัตโนมัติ',
          u: 'https://www.youtube.com/watch?v=XfCFbroBH9k',
        },
      ],
    },
    {
      q: 'การตั้งค่า "ยกเลิกการจัดส่งอัตโนมัติเมื่อออเดอร์ถูกยกเลิก" คืออะไร',
      a:
        'เมื่อออเดอร์ที่ทางร้านจัดส่งออกไปแล้วถูกยกเลิกจากช่องทางขาย เช่น ลูกค้าแจ้งยกเลิกออเดอร์ XSelly ก็จะอัพเดทสถานะออเดอร์ในระบบให้เป็นยกเลิกตาม โดยหากการตั้งค่านี้*เปิด*อยู่ XSelly ก็จะยกเลิกการบันทึกจัดส่งของออเดอร์นี้ตามไปด้วยโดยอัตโนมัติ ทำให้สต๊อกสินค้าในออเดอร์นี้ดีดกลับเพิ่มขึ้นตามจำนวนในออเดอร์ และอัพเดทเพิ่มสต๊อกนี้กลับไปที่ช่องทางขายที่ผูกอยู่ทั้งหมดทันที ทำให้ลูกค้าใหม่สามารถเปิดออเดอร์ใหม่มาได้ทันทีเช่นกัน',
    },
    {
      q: 'เมื่อไหร่ควร*เปิด* หรือ *ปิด* การตั้งค่านี้',
      a:
        'ควร *เปิด* เมื่อ คุณมีสต๊อกสินค้าเป็นจำนวนมากซึ่งแม้จะยังไม่ได้รับพัสดุตีกลับ ก็มีสินค้าเพียงพอต่อการจัดส่งเมื่อมีออเดอร์เปิดมาใหม่ควร ปิด เมื่อคุณมีสต็อกสินค้าไม่มากเช่น เป็น เสื้อผ้าแบบ A Size M ที่มีเพียง 1 ตัว เมื่อจัดส่งออกไปแล้วทำให้เหลือ 0 ตัว การปิดการตั้งค่านี้จะทำให้สต๊อกไม่ดีดกลับเป็น 1 ทันทีที่ออเดอร์ถูกยกเลิก ทำให้คุณสามารถรอพัสดุนี้ตีกลับมาถึงที่ร้านก่อนที่จะดีดสต๊อกกลับเองเพื่อเปิดรับออเดอร์ใหม่ๆได้',
    },
    {
      q: 'ถ้า*ปิด*การตั้งค่าแล้ว จะบันทึกการตีกลับให้สต๊อกดีดกลับอย่างไร',
      a:
        '1. ทำการยกเลิกการจัดส่งของออเดอร์ที่ถูกตีกลับ โดยเปิดหน้ารายละเอียดออเดอร์ (สามารถสแกนหรือพิมพ์หาเลขออเดอร์) > กดปุ่ม "ฉันจัดส่งแล้ว" > กดปุ่ม "ยกเลิกการจัดส่งนี้" ซึ่งจะทำให้สต๊อกสินค้าทั้งหมดในออเดอร์ดีดกลับและอัพเดทไปที่ช่องทางขาย\n' +
        '2 เปิดหน้ารายละเอียดสินค้า > กดปุ่ม "แก้ไขคลัง" > ดำเนินการเพิ่มคลังกลับเข้าไปเอง',
    },
  ],
  MULTI_PARCEL_CANCEL_ARRANGEMENT: [
    {
      q: 'เมื่อไหร่ควรทำการ*ยกเลิกการแบ่งพัสดุทั้งหมด*',
      a:
        '1. เมื่อไม่ต้องการใช้งานการแบ่งพัสดุแล้ว เช่น ต้องการพิมพ์ใบปะหน้าพัสดุเพียงใบเดียว' +
        '\nหรือ\n2. เมื่อคุณต้องการทำการแบ่งพัสดุใหม่ เช่น มีการบันทึกจัดส่งสินค้าไปบางส่วนแล้ว หรือ มีการเปลื่ยนแปลงการจัดพัสดุ',
    },
    {
      q: 'อะไรบ้างจะ *ถูกยกเลิก*',
      a:
        '1. พัสดุทั้งหมดที่*ไม่มี*เลขติดตามพัสดุ XShipping' +
        '\n2. พัสดุทั้งหมดที่*มี*เลขติดตามพัสดุ XShipping และทางขนส่งได้ยิงสแกนเข้าระบบแล้ว' +
        '\n3. เลขติดตามพัสดุ XShipping ของพัสดุที่จะถูกยกเลิกตามข้อ 2.',
    },
    {
      q: 'อะไรบ้างจะ *ไม่ถูกยกเลิก*',
      a: 'พัสดุที่มีเลขติดตามพัสดุ XShipping ที่ทางขนส่งได้ยิงสแกนเข้าระบบแล้ว',
    },
    {
      q: 'สามารถยกเลิกการจัดแบ่งเฉพาะ*บางพัสดุ*ได้ไหม',
      a:
        'ไม่ได้ การยกเลิกการจัดแบ่งพัสดุจะมีผลกับ*ทุกพัสดุที่เข้าเงื่อนไข*ด้านบน (อะไรบ้างจะ*ถูกยกเลิก*)\n\nเมื่อยกเลิกแล้วคุณสามารถทำการระบุใหม่ หรือไม่ต้องระบุหากไม่ต้องการแบ่งพัสดุแล้ว',
    },
    {
      q: 'ถ้ามีบางพัสดุมีการบันทึกจัดส่งจากขนส่งไปแล้ว จะเกิดอะไรขึ้นเมื่อยกเลิก',
      a:
        'พัสดุตามเงื่อนไขด้านบน (อะไรบ้างจะ*ถูกยกเลิก*) จะถูกยกเลิกการจัดแบ่ง และคุณจะสามารถจัดแบ่งพัสดุใหม่ได้สำหรับรายการสินค้าในพัสดุที่ถูกยกเลิกการจัดแบ่งไป',
    },
    {
      q: 'ถ้าออเดอร์การบันทึกจัดส่งไปแล้วบางส่วน สามารถจัดแบ่งพัสดุใหม่สำหรับสินค้าที่ยังไม่ได้จัดส่งได้ไหม',
      a:
        'ได้! คุณสามารถยกเลิกการแบ่งพัสดุเดิม และทำการแบ่งใหม่ได้ โดยระบบจะแสดงเพียงเฉพาะรายการสินค้าที่*ยังไม่ได้บันทึกจัดส่ง*ให้คุณได้แบ่งพัสดุ ขอเลขติดตามพัสดุ XShipping และพิมพ์ใบปะหน้าได้ใหม่',
    },
  ],
  VAT_STORE_SETTING_BILL: [
    {
      q: 'VAT',
      a:
        'ระบบจะคิดจากยอดสินค้าที่ระบุไว้ในแอปเป็นยอดที่รวมภาษีมูลค่าเพิ่ม (VAT) แล้ว ดังนั้นการเปิดการแสดง VAT นี้จะเป็นการคำนวณยอด VAT ที่รวมอยู่ในค่าสินค้าอยู่แล้ว เช่น ถ้ายอดสินค้าราคา 100 บาท ยอด VAT จะแสดงที่ 6.54 บาท เป็นต้น',
    },
  ],
  RECEIPT_HEAD_BILL_FAQ: [
    {
      q: 'วิธีตั้งค่ารูปหัวใบเสร็จ/ท้ายใบเสร็จ',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/add_header_img_receipt.png',
          t: 'วิธีตั้งค่าเพิ่มรูปหัวใบเสร็จ/ทายใบเสร็จ',
          u: 'https://www.youtube.com/watch?v=JhYBeGRQI-0',
        },
      ],
    },
    {
      q: 'เพิ่มรูปหัวใบเสร็จ ท้ายใบเสร็จเมื่อไหร่?',
      a:
        'เพิ่มเมื่อต้องการให้ใบเสร็จของคุณมีโลโก้ร้านค้าเพื่อเป็นการยืนยันว่า คุณได้รับเงิน หรือ ลูกค้าได้ทำการชำระเงินเรียบร้อยแล้วและทำให้ใบเสร็จเป็นเอกสารที่สามารถเป็นหลักฐานได้ว่าออกมาจากร้านค้าของคุณ โดยขนาดรูปที่เหมาะสมตามในคลิปตัวอย่างจะอยู่ที่ขนาด 750*320 px.',
    },
  ],
  LINE_SHOPPING_API_KEY_FAQ: [
    {
      q: 'ทำไมต้องขอ API Key',
      a: 'เป็นวิธีการเชื่อมต่อของ Line ที่ไว้ใช้ระบุตัวตนว่ามาจากร้านไหน',
    },
    {
      q: 'จะขอ Line API Key ได้อย่างไร',
      a: 'กดปิดหน้าต่างนี้ จากนั้นให้กดปุ่ม "ลงทะเบียนเพื่อขอ API Key"',
    },
    {
      q: 'ลงทะเบียนเสร็จแล้วต้องทำอย่างไร',
      a: 'ต้องรอ Line ยืนยันข้อมูลทุกๆวันจันทร์และวันพุธของแต่ละสัปดาห์เวลา 12:00 น.',
    },
    // {
    //   q: 'คลิป',
    //   a: 'ต้องรอ Line ยืนยันข้อมูลทุกๆวันจันทร์และวันพุธของแต่ละสัปดาห์เวลา 12:00 น.',
    // },
  ],
  COPY_LINK_ORDER_FAQ: [
    {
      q: 'วิธีตั้งค่าปรับแต่งลิงก์ออเดอร์',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/order/Linkorder.png',
          t: 'วิธีตั้งค่าปรับแต่งลิงก์ออเดอร์',
          u: 'https://www.youtube.com/watch?v=H4NZK5tRN4Y',
        },
      ],
    },
  ],
  SHIPPING_TYPE_SELECTOR_FAQ: [
    {
      q: 'วิธีซ่อน-แสดง รูปแบบจัดส่ง',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/hide_show_xhipping.png',
          t: 'วิธีซ่อน-แสดง รูปแบบจัดส่ง',
          u: 'https://www.youtube.com/watch?v=dOoRBLiIW7E',
        },
      ],
    },
    {
      q: 'ทำไมต้องซ่อน-แสดง รูปแบบจัดส่ง',
      a:
        'เพื่อเพิ่มความสะดวกและรวดเร็วในการเลือกขนส่งที่ต้องการ เพราะใน XSelly ในมีขนส่งให้เลือกมากมาย คุณสามารถซ่อนขนส่งที่ไม่ใช้งานได้ ทำให้เหลือเฉพาะขนส่งที่ใช้งานเป็นประจำให้เลือกในรายการ',
    },
  ],
  MKP_CONNECT_FAQ: [
    {
      q: 'ทำไมต้องเชื่อมช่องทางการขาย',
      a:
        'เมื่อทางร้านค้ามีการขายผ่านช่องทางการขายต่างๆ เช่น Shopee Lazada Tiktok Line Shopping ทำให้สต๊อกสินค้ากระจัดกระจาย ดูแลจัดการสต๊อกออเดอร์ได้ยาก เมื่อคุณใช้วิธีการเชื่อมสต๊อกสินค้าตามช่องทางการขายเข้ากับ XSelly  จะทำให้ร้านค้าคุณดูแลจัดการสต๊อก ออเดอร์ ได้ง่ายขึ้น',
    },
    {
      q: 'เชื่อมช่องทางการขายกับ XSelly ดีอย่างไร ?',
      a:
        '- เชื่อมสต๊อกกับบนช่องทางการขาย  Shopee Lazada Tiktok Line Shopping อัพเดทผ่าน แอป XSelly ได้เลย\n' +
        '- ดูดออเดอร์อัตโนมัติจาก Shopee Lazada Tiktok Line Shopping มาลงใน XSelly\n' +
        '- ดำเนินการยืนยันออเดอร์บน Shopee Lazada Tiktok Line Shopping ให้อัตโนมัติทั้ง Dropoff และ นัดรับ\n' +
        '- พิมพ์ใบปะหน้าผ่าน XSelly ได้เลย\n' +
        '- ใช้งานได้ตั้งแต่แพ็ก Gold ขึ้นไป หรือ แพ็ก Silver ที่บวกแพ็กเสริม 200 บาท/เดือน ต่อการเชื่อม 1 ร้านบน Shopee',
    },
  ],
  MKP_CHANNELS_CONNECT_FAQ: [
    {
      q: 'คลิปวิธีเชื่อมช่องทางขาย Shopee',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_shopee.png',
          t: 'วิธีเชื่อมสต๊อก Shopee',
          u: 'https://www.youtube.com/watch?v=R53WlTEG5kQ',
        },
      ],
    },
    {
      q: 'คลิปวิธีเชื่อมช่องทางขาย Lazada',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_lazada.png',
          t: 'วิธีเชื่อมสต๊อก Lazada',
          u: 'https://www.youtube.com/watch?v=PJfcAu2h7PQ',
        },
      ],
    },
    {
      q: 'คลิปวิธีเชื่อมช่องทางขาย Tiktok',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_tiktok.png',
          t: 'วิธีเชื่อมสต๊อก Tiktok',
          u: 'https://www.youtube.com/watch?v=jbz_37rRAlc',
        },
      ],
    },
    {
      q: 'คลิปวิธีเชื่อมช่องทางขาย LINE SHOPPING',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_LineShopping.png',
          t: 'วิธีเชื่อมสต๊อก LINE SHOPPING',
          u: 'https://www.youtube.com/watch?v=8_8t5mykSFs',
        },
      ],
    },
  ],
  HELPER_FAQ: [
    {
      q: 'วิธีสร้างกลุ่มผู้ช่วยร้านและตั้งค่าสิทธิ์ให้ผู้ช่วย',
      v: [
        {
          i: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/group-helper-agent/set_help.png',
          t: 'วิธีสร้างกลุ่มผู้ช่วยร้านและตั้งค่าสิทธิ์ให้ผู้ช่วย',
          u: 'https://www.youtube.com/watch?v=x-M9QkeeVGI',
        },
      ],
    },
  ],
  XSHIPPING_SPX_AGREEMENT: [
    {
      q: 'ต้องการเชื่อมต่อ SPX ต้องทำอย่างไร',
      a: 'หากต้องการเชื่อมต่อ SPX กรุณาติดต่อทีมงาน',
    },
  ],
}

const convertObjectToKeysObject = (obj) => {
  const initialValue = {}
  const keys = Object.keys(obj)
  return keys.reduce(
    (currKeysObj, item) => ({
      ...currKeysObj,
      [item]: item,
    }),
    initialValue
  )
}

export type IFaqKey = keyof typeof FAQ
type IFaqKeysObject = { [P in IFaqKey]: Extract<IFaqKey, P> }
export const FAQ_KEYS = convertObjectToKeysObject(FAQ) as IFaqKeysObject

export default FAQ
