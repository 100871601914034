/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'
import api from 'x/utils/api'

import * as xNavActions from 'x/utils/navigation'
import { IXShippingConfig, IXShippingSystemAccount } from 'x/config/app'
import { Image, ImageRequireSource } from 'react-native'
import { ISelectedStoreMap, IXScreenProps } from 'x/types'

export interface BaseStoreAssistantMemberProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  getXShippingConfigFromRedux: IXShippingConfig[]
  getXshippingSystemAccountFromRedux: IXShippingConfig[]
  setXShippingConfig: (xshippingCofig: IXShippingConfig[]) => void
  submitEditingXShipping: (body: any) => any
  setXShippingSystemAccount: (xshippingSystemAccount: IXShippingSystemAccount[]) => void
}

export interface BaseStoreAssistantMemberState {
  useXShipping: boolean
  shippingMenuList: IShippingMenuList[]
  isPageLoading: boolean
  pageLoadingWidth: number
  pageLoadingHeight: number
}

export interface IShippingMenuList {
  shippingName: string
  isActive: () => boolean
  nav: () => void
  shippingImg: JSX.Element
}

const IMG_JT: ImageRequireSource = require('../../../../xui/x/images/courier/jt.png')
const IMG_SCG: ImageRequireSource = require('../../../../xui/x/images/courier/scg.png')
const IMG_KERRY: ImageRequireSource = require('../../../../xui/x/images/courier/kerry.png')
const IMG_FLASH: ImageRequireSource = require('../../../../xui/x/images/courier/flash.png')
const IMG_BEST: ImageRequireSource = require('../../../../xui/x/images/courier/best.png')
const IMG_EMS: ImageRequireSource = require('../../../../xui/x/images/courier/logo_ems.png')
const IMG_ECOPOST: ImageRequireSource = require('../../../../xui/x/images/courier/logo_ecopost.png')
const IMG_DHL: ImageRequireSource = require('../../../../xui/x/images/courier/dhl.png')
const IMG_NINJA: ImageRequireSource = require('../../../../xui/x/images/courier/ninja.png')
const IMG_SPX: ImageRequireSource = require('../../../../xui/x/images/courier/spx.png')

export default abstract class BaseStoreAssistantMember extends React.Component<
  BaseStoreAssistantMemberProps,
  BaseStoreAssistantMemberState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // _backHandler?: NativeEventSubscription

  protected constructor(props) {
    super(props)

    this.state = {
      useXShipping: false,
      shippingMenuList: null,
      isPageLoading: true,
      pageLoadingWidth: 0,
      pageLoadingHeight: 0,
    }
    // this._backHandler = BackHandler.addEventListener('hardwareBackPress', this._onPressBackWhileDirtyData)
  }

  async componentDidMount() {
    await this._fetchXShippingConfig()
    // await util.delay(200)
    await this._setMenuList()
    await util.setStatePromise(this, {
      isPageLoading: false,
    })
  }

  componentWillUnmount() {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { onPressGoBack } = state.params
    // // console.log('onPressGoBack => ', onPressGoBack)
    const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
    if (_.isFunction(onPressGoBack)) {
      onPressGoBack()
    }
  }

  // _onPressBackWhileDirtyData = () => {
  //   const { navigation } = this.props
  //   const { state, goBack } = navigation
  //   const { onPressGoBack } = state.params
  //   // console.log('onPressGoBack => ', onPressGoBack)
  //   if (_.isFunction(onPressGoBack)) {
  //     onPressGoBack()
  //   }
  //   if (this._backHandler) {
  //     this._unSetBackHandler()
  //   }
  //   goBack(state.key)
  //   // this.goBack() // works best when the goBack is async
  //   return true
  // }

  _fetchXShippingConfig = async () => {
    const { selectedStore, setXShippingConfig, setXShippingSystemAccount } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    const response: { xshipping_config: IXShippingConfig[]; system_accounts: IXShippingSystemAccount[] } = await api.postV2(
      api.XSHIPPING_CONFIG,
      body
    )
    // console.log('_updateXshippingConfig => ', response)
    if (response.xshipping_config && _.isArray(response.xshipping_config)) {
      setXShippingConfig(response.xshipping_config)
    }
    if (response.system_accounts && _.isArray(response.system_accounts)) {
      setXShippingSystemAccount(response.system_accounts)
    }
  }

  _jtConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.jt'),
    isActive: () => this.menuIsActive([38, 39, 44, 65]),
    nav: () => this.navToStoreSettingXShippingJAndT(),
    shippingImg: (
      <Image
        source={IMG_JT}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 120,
          height: 40,
          backgroundColor: 'transparent',
          marginLeft: -8,
          zIndex: 70,
        }}
      />
    ),
  })

  _kerryConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.kerry'),
    isActive: () => this.menuIsActive([4, 24]),
    nav: () => this.navToStoreSettingXShippingKerry(),
    shippingImg: (
      <Image
        source={IMG_KERRY}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 120,
          height: 40,
          backgroundColor: 'transparent',
          marginLeft: -8,
          zIndex: 70,
        }}
      />
    ),
  })

  _flashConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.flash'),
    isActive: () => this.menuIsActive([22, 30]),
    nav: () => this.navToStoreSettingXShippingFlash(),
    shippingImg: (
      <Image
        source={IMG_FLASH}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 154,
          height: 58,
          backgroundColor: 'transparent',
          marginLeft: -20,
          zIndex: 70,
        }}
      />
    ),
  })

  _bestConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.best'),
    isActive: () => this.menuIsActive([35, 36]),
    nav: () => this.navToStoreSettingXShippingBest(),
    shippingImg: (
      <Image
        source={IMG_BEST}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 138,
          height: 40,
          backgroundColor: 'transparent',
          marginLeft: -10,
          zIndex: 70,
        }}
      />
    ),
  })

  _emsConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.ems'),
    isActive: () => this.menuIsActive([3]),
    nav: () => this.navToStoreSettingXShippingEMS(),
    shippingImg: (
      <Image
        source={IMG_EMS}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 93,
          height: 18,
          backgroundColor: 'transparent',
          marginLeft: 10,
          zIndex: 70,
        }}
      />
    ),
  })

  _ecoPostConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.ecopost'),
    isActive: () => this.menuIsActive([71, 72]),
    nav: () => this.navToStoreSettingXShippingEcoPost(),
    shippingImg: (
      <Image
        source={IMG_ECOPOST}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 96,
          height: 28,
          backgroundColor: 'transparent',
          marginLeft: 10,
          zIndex: 70,
        }}
      />
    ),
  })

  _dhlConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.dhl'),
    isActive: () => this.menuIsActive([14, 29]),
    nav: () => this.navToStoreSettingXShippingDHL(),
    shippingImg: (
      <Image
        source={IMG_DHL}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 96,
          height: 30,
          backgroundColor: 'transparent',
          marginLeft: 10,
          zIndex: 70,
        }}
      />
    ),
  })

  _ninjaConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.ninja'),
    isActive: () => this.menuIsActive([7, 26]),
    nav: () => this.navToStoreSettingXShippingNinjaVan(),
    shippingImg: (
      <Image
        source={IMG_NINJA}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 135,
          height: 45,
          backgroundColor: 'transparent',
          marginLeft: -10,
          zIndex: 70,
        }}
      />
    ),
  })

  _scgConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.scg'),
    isActive: () => this.menuIsActive([13, 28]),
    nav: () => this.navToStoreSettingXShippingSCG(),
    shippingImg: (
      <Image
        source={IMG_SCG}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 135,
          height: 45,
          backgroundColor: 'transparent',
          marginLeft: -10,
          zIndex: 70,
        }}
      />
    ),
  })

  _spxConfig = () => ({
    shippingName: p.op.t('Order.shippingTypeItems.shopee_express'),
    isActive: () => this._systemIsActive(11),
    nav: () => this.navToStoreSettingXShippingSpx(),
    shippingImg: (
      <Image
        source={IMG_SPX}
        resizeMode='stretch'
        style={{
          // position: 'absolute',
          width: 75,
          height: 30,
          backgroundColor: 'transparent',
          marginLeft: 10,
          zIndex: 70,
        }}
      />
    ),
  })

  _setMenuList = async () => {
    const { selectedStore } = this.props
    const SHIPPING_MENU_LIST: IShippingMenuList[] = [
      this._jtConfig(),
      this._kerryConfig(),
      this._flashConfig(),
      this._emsConfig(),
      this._ecoPostConfig(),
      // this._spxConfig(),
      this._bestConfig(),
      this._dhlConfig(),
      // this._ninjaConfig(),
      // this._scgConfig(),
    ]
    if (util.isDevMode()) {
      SHIPPING_MENU_LIST.push(this._spxConfig())
    }
    const s_use_xshipping = selectedStore.get('s_use_xshipping')
    // console.log('s_use_xshipping => ', s_use_xshipping)
    await util.setStatePromise(this, {
      shippingMenuList: SHIPPING_MENU_LIST,
      useXShipping: s_use_xshipping,
    })
  }

  _systemIsActive = (shippingTypeId: number) => {
    const { getXshippingSystemAccountFromRedux } = this.props
    if (!_.isNil(getXshippingSystemAccountFromRedux) && getXshippingSystemAccountFromRedux.length > 0) {
      // Check if the system account is active for the given shipping type ID
      let active = false
      getXshippingSystemAccountFromRedux.forEach((system) => {
        if (system.system_id === shippingTypeId) {
          active = true
        }
      })
      return active
    }
    return false
  }

  menuIsActive = (shippingTypeId: number[]) => {
    const { getXShippingConfigFromRedux } = this.props
    if (_.isNil(shippingTypeId) || shippingTypeId.length < 1) {
      return false
    }
    // console.log('getXShippingConfigFromRedux => ', getXShippingConfigFromRedux)
    if (!_.isNil(getXShippingConfigFromRedux) && getXShippingConfigFromRedux.length > 0) {
      let isKerryActive = false
      // eslint-disable-next-line array-callback-return
      getXShippingConfigFromRedux.map((config: IXShippingConfig) => {
        if (_.includes(shippingTypeId, config.shipping_type_id)) {
          isKerryActive = true
        }
        // if (config.shipping_type_id === 4 || config.shipping_type_id === 24) {
        //   // console.log('config => ', config)
        //   isKerryActive = true
        // }
      })
      return isKerryActive
    }
    return false
  }

  useXShippingOnChange = async (newValue: boolean) => {
    if (!acl.isSelectedStoreOwner()) {
      p.op.showConfirmationOkOnly('', p.op.t('hleper.you_are_not_authorized_to_use_this_feature'))
      return
    }
    const { selectedStore } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      s_use_xshipping: newValue,
    }
    const response: { store: any } = await api.patch(api.PATCH_MY_STORE, body)
    // console.log('response => ', response)
    if (response.store) {
      await util.setStatePromise(this, {
        useXShipping: newValue,
      })
    } else {
      await util.setStatePromise(this, {
        useXShipping: !newValue,
      })
    }
  }

  onPressGoBackFromShipping = () => {
    // console.log('onPressGoBackFromShipping => ')
    this._setMenuList()
  }

  // _unSetBackHandler = () => {
  //   if (this._backHandler) {
  //     this._backHandler.remove()
  //   }
  // }

  navToStoreSettingXShippingJAndT = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingJAntT({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingKerry = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingKerry({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingFlash = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingFlash({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingBest = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingBast({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingNinjaVan = () => {
    this.props.navigation.dispatch(
      xNavActions.navToStoreSettingXShippingNinjaVan({ onPressGoBack: () => this.onPressGoBackFromShipping() })
    )
  }

  navToStoreSettingXShippingSCG = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingSCG({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingDHL = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingDHL({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingEMS = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingEMS({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingEcoPost = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingEcoPost({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  navToStoreSettingXShippingSpx = () => {
    this.props.navigation.dispatch(xNavActions.navToStoreSettingXShippingSpx({ onPressGoBack: () => this.onPressGoBackFromShipping() }))
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    let isConfirm = false
    if (newValue.stateKey === 'useXShipping') {
      // เปิดใช้งานแล้ว ต้องเลือกออกเลขพัสดุขนส่งที่ต้องการด้วย
      let alertText = 'หลังจากเปิดใช้งาน XShipping แล้ว กรุณาเลือกเปิดขนส่งที่ต้องการใช้งานด้านล่าง'
      let alertTextHeader = 'เปิดใช้งาน XShipping'
      if (!newValue.isValue) {
        alertText = 'หลังจากปิดใช้งาน XShipping แล้ว ระบบจะปิดการใช้งานออกเลขพัสดุทุกขนส่งที่เปิดใช้งาน'
        alertTextHeader = 'ปิดใช้งาน XShipping'
      }
      await new Promise((resolve) => {
        p.op.showConfirmation(
          alertTextHeader,
          alertText,
          () => {
            isConfirm = true
            resolve(null)
          },
          () => {
            resolve(null)
          },
          'ตกลง',
          'ยกเลิก'
        )
      })
    }

    if (!isConfirm) {
      await util.setStatePromise(this, {
        [newValue.stateKey]: !newValue.isValue,
      })
      return
    }

    if (newValue.stateKey === 'useXShipping') {
      const { selectedStore, submitEditingXShipping } = this.props
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const body: { [key: string]: any } = {
        store_id: selectedStore.get('id'),
        s_use_xshipping: newValue.isValue,
      }
      // const response: { store: any } = await api.patch(api.PATCH_MY_STORE, body)
      // const response: { store: any } = await submitEditingXShipping(body)
      const response: { store: any } = await new Promise((resolve) => {
        submitEditingXShipping({
          body: { store_id: selectedStore.get('id'), s_use_xshipping: newValue.isValue },
          successCallback: resolve,
          failedCallback: () => resolve(null),
        })
      })
      // console.log('response => ', response)
      // return
      if (response.store) {
        await util.setStatePromise(this, {
          useXShipping: newValue.isValue,
        })
      } else {
        await util.setStatePromise(this, {
          useXShipping: !newValue.isValue,
        })
      }

      // util.setStatePromise(this, {
      //   [newValue.stateKey]: newValue.isValue,
      // })
    }
  }
}
